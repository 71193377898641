import React from 'react';

function MtzPagination({ total, onChange, defaultPerPage }) {
    const perPages = [16, 32, 64, 128];
    let [page, setPage] = React.useState(0);
    let [perPage, setPerPage] = React.useState(defaultPerPage || 16);
    let [maxPage, setMaxPage] = React.useState(0);
    let [genPages, setGenPages] = React.useState([]);

    React.useEffect(() => {
        maxPage = total ? Math.ceil(total / perPage) : 0;
        setMaxPage(maxPage);

        // get pagination buttons
        genPages = [];
        ([-4, - 3, - 2, -1, 0, 1, 2, 3, 4]).map(i => {
            if (page + i >= 0 && page + i < maxPage)
                if (genPages.length < 5)
                    genPages.push(page + i);
        });
        setGenPages(genPages);
    }, [total]);

    const paginationChange = (newPerPage, newPage) => {
        if (newPerPage >= 0) {
            perPage = newPerPage;
            setPerPage(perPage);

            maxPage = total ? Math.ceil(total / perPage) : 0;
            setMaxPage(maxPage);

            page = 0;
            setPage(page);
        }

        if (newPage >= 0) {
            page = newPage;
            setPage(page);
        }

        // get pagination buttons
        genPages = [];
        ([-4, - 3, - 2, -1, 0, 1, 2, 3, 4]).map(i => {
            if (page + i >= 0 && page + i < maxPage)
                if (genPages.length < 5)
                    genPages.push(page + i);
        });
        setGenPages(genPages);

        let skip = page * perPage;
        let limit = perPage;
        if (onChange)
            onChange(skip, limit);
    };

    return (
        <div>
            <div className='d-flex mtz-gap-16 align-items-center my-3'>
                <div className='flex-fill d-flex flex-wrap mtz-gap-8 align-items-center'>
                    <label className='m-0 p-0'>Number of pages (&nbsp;{maxPage}&nbsp;):</label>
                    <ul className="pagination m-0">
                        {
                            genPages.map(p => (
                                <li key={p} onClick={() => paginationChange(undefined, p)}
                                    className={`page-item${page == p ? ' active' : ''}`}>
                                    <button className="page-link">{p}</button>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className='d-flex mtz-gap-8 align-items-center'>
                    <label className='m-0 p-0 text-nowrap'>Per page: </label>
                    <select className='form-control' onClick={e => paginationChange(e.target.value, undefined)}>
                        {
                            perPages.map(o => (
                                <option key={o} value={o}>{o}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <h5>Total: {total}</h5>
        </div>
    );
}

export default MtzPagination;