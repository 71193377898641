import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMtzWalletDeposits = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-deposits?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const createMtzWalletDeposit = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-deposits`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const getMtzWalletDepositStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-deposits/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMtzWalletDepositById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-deposits/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  getMtzWalletDeposits,
  getMtzWalletDepositById,
  getMtzWalletDepositStats,
  createMtzWalletDeposit,
}