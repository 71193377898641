import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCompanyAdKeywords = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyAdKeywordStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyAdKeywordById = (companyAdKeywordId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords/${companyAdKeywordId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCompanyAdKeyword = (companyAdKeyword) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyAdKeyword, headers);
};

export const deleteCompanyAdKeywordById = (companyAdKeywordId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords/${companyAdKeywordId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const deleteCompanyAdKeywords = (queries) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCompanyAdKeywordById = (companyAdKeywordId, companyAdKeyword) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords/${companyAdKeywordId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyAdKeyword, headers);
};

export const clickCompanyAdKeywordById = (companyAdKeywordId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keywords/click/${companyAdKeywordId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};