import commonUtil from "../common";
import {
  METRIC_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const getUserSessionStats = async queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-session-stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getUserSessionStatStats = async queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-session-stats/stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
}

export const getUserSessionDistinctStats = async (field, queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-session-stats/distinct?${decodeURIComponent(queries)}&distinct=${field}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getUserSessionDistinctStatStats = async (field, queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-session-stats/stats/distinct?${decodeURIComponent(queries)}&distinct=${field}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
}

