import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getPrices = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/prices?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getPriceById = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/prices?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const createPrice = (params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/prices`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const updatePriceById = (priceId, price) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/prices/` + priceId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, price, headers);
};

const deletePriceById = (priceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/prices/` + priceId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const deletePrices = queries => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/prices?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export {
  getPriceById,
  getPrices,
  createPrice,
  updatePriceById,
  deletePriceById,
  deletePrices
}