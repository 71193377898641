import React from 'react';

import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

function FooterHori(props) {

  return (
    <div className="w-100">
      <div className="d-flex mtz-gap-4 flex-wrap">
        <NavLink className='text-muted' to="/terms"><small>Terms</small></NavLink>&nbsp;|&nbsp;
        <NavLink className='text-muted' to="/privacy"><small>Privacy</small></NavLink>&nbsp;|&nbsp;
        <a className="text-muted" href="/forms/62dffe56dd0aa361988a8786?tc=62dffebadd0aa361988a8787"><small>Feedback</small></a>&nbsp;|&nbsp;
        <NavLink className='text-muted' to="/faqs"><small>FAQs</small></NavLink>&nbsp;|&nbsp;
        <a className="text-muted" href="/forms/6548fc6d51aa8537da6730d8"><small>Investor Relations</small></a>&nbsp;|&nbsp;
        <NavLink className='text-muted' to="/about"><small>About Us</small></NavLink>&nbsp;|&nbsp;
        <NavLink className='text-muted' to="/contact"><small>Contact Us</small></NavLink>&nbsp;|&nbsp;
        <a className="text-muted" href="/mtz-news"><small>MyTradeZone News</small></a><br /><br />
        {/* <a className="text-muted" href="/sitemap.xml"><small>Sitemap</small></a>*/}
        <div>
          <small className='text-muted'><b>
            MyTradeZone &copy; {(new Date()).getFullYear()}
          </b></small>
        </div>
      </div>
    </div>
  );
}

export default FooterHori;
