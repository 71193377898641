import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyGroupMember = (companyGroupMember) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMember, headers);
};

export const deleteCompanyGroupMemberById = (companyGroupMemberId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/${companyGroupMemberId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupMemberById = (companyGroupMemberId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/${companyGroupMemberId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupMembers = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupMemberStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCompanyGroupMemberById = (companyGroupMemberId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/${companyGroupMemberId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};