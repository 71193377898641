import commonUtil from "../common";
import {
    METRIC_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const getCommomStats = async (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/common-stats?${decodeURIComponent(
        queries
    )}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCommonStatsStat = async (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/common-stats/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return await commonUtil.httpclient(method, url, {}, headers);
};

