import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGSponsorPlan = (companyGroupSponsorPlan) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plans`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupSponsorPlan, headers);
};

export const deleteCGSponsorPlanById = (companyGroupSponsorPlanId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plans/${companyGroupSponsorPlanId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGSponsorPlanById = (companyGroupSponsorPlanId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plans/${companyGroupSponsorPlanId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGSponsorPlanById = (companyGroupSponsorPlanId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plans/${companyGroupSponsorPlanId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorPlans = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plans?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorPlanStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-plans/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};