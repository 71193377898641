import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGAdBanner = (cgAdBanner) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgAdBanner, headers);
};

export const deleteCGAdBannerById = (cgAdBannerId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners/${cgAdBannerId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGAdBannerById = (cgAdBannerId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners/${cgAdBannerId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGAdBanners = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCGAdBannerStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCGAdBannerById = (cgAdBannerId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners/${cgAdBannerId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGAdBannerByGroupId = (companyGroupId, queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners/group/${companyGroupId}?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGAdBannerStatsByGroupId = (companyGroupId, queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-ad-banners/stats/group/${companyGroupId}?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};
