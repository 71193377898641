import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCGEventInterests = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-interests?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGEventInterestStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-interests/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGEventInterestById = (cgEventInterestId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-interests/${cgEventInterestId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCGEventInterest = (cgEventInterest) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-interests`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgEventInterest, headers);
};

export const deleteCGEventInterestById = (cgEventInterestId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-interests/${cgEventInterestId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCGEventInterestById = (cgEventInterestId, cgEventInterest) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-interests/${cgEventInterestId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgEventInterest, headers);
};
