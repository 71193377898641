import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createProductUploadEvent = (productUploadEvent) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events`;
  let headers = {};
  return commonUtil.httpclient(method, url, productUploadEvent, headers);
};

export const deleteProductUploadEventById = (productUploadEventId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events/${productUploadEventId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getProductUploadEventById = (productUploadEventId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events/${productUploadEventId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getProductUploadEvents = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getProductUploadEventStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateProductUploadEventById = (productUploadEventId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events/${productUploadEventId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};

export const getProductUploadTemplate = (categoryIds) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-upload-events/template?${decodeURIComponent(
    categoryIds
  )}`;
  let headers = {};
  return commonUtil.httpclient(method, url, null, headers);
};
