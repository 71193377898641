import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGSponsorAd = (cgSponsorAd) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-ads`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgSponsorAd, headers);
};

export const deleteCGSponsorAdById = (cgSponsorAdId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-ads/${cgSponsorAdId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorAdById = (cgSponsorAdId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-ads/${cgSponsorAdId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorAds = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-ads?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCGSponsorAdStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-ads/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCGSponsorAdById = (cgSponsorAdId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-ads/${cgSponsorAdId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, params, headers);
};
