import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCompanyExtraQuestionChoices = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-choices?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getCompanyExtraQuestionChoiceById = (companyExtraQuestionChoiceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-choices/` + companyExtraQuestionChoiceId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyExtraQuestionChoiceId, headers);
};

const deleteCompanyExtraQuestionChoiceById = (companyExtraQuestionChoiceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-choices/${companyExtraQuestionChoiceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const createCompanyExtraQuestionChoice = (newCompanyExtraQuestionChoice) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-choices`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newCompanyExtraQuestionChoice, headers);
};

const updateCompanyExtraQuestionChoiceById = (companyExtraQuestionChoiceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-choices/${companyExtraQuestionChoiceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export {
  getCompanyExtraQuestionChoices,
  getCompanyExtraQuestionChoiceById,
  deleteCompanyExtraQuestionChoiceById,
  updateCompanyExtraQuestionChoiceById,
  createCompanyExtraQuestionChoice
}