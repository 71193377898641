import { combineReducers } from 'redux';

import callEventReducer from './callEventReducer';
import callInviteEventReducer from './callInviteEventReducer';
import companyEventReducer from './companyEventReducer';
import companySearchEventReducer from './companySearchEventReducer';
import connectionEventReducer from './connectionEventReducer';
import directRoomEventReducer from './directRoomEventReducer';
import messageEventReducer from './messageEventReducer';
import messageFileEventReducer from './messageFileEventReducer';
import messageViewerEventReducer from './messageViewerEventReducer';
import mtzPlanSubscriptionEventReducer from './mtzPlanSubscriptionEventReducer';
import notificationEventReducer from './notificationEventReducer';
import presenceEventReducer from './presenceEventReducer';
import presenceSessionIdEventReducer from './presenceSessionIdEventReducer';
import roomEventReducer from './roomEventReducer';
import roomInviteEventReducer from './roomInviteEventReducer';
import rtcRequestEventReducer from './rtcRequestEventReducer';
import userEventReducer from './userEventReducer';

const rootReducer = combineReducers({
	notification_topic: notificationEventReducer,
	connection_topic: connectionEventReducer,
	user_topic: userEventReducer,
	company_topic: companyEventReducer,
	message_topic: messageEventReducer,
	messageFile_topic: messageFileEventReducer,
	messageViewer_topic: messageViewerEventReducer,
	call_topic: callEventReducer,
	callInvite_topic: callInviteEventReducer,
	companySearch_topic: companySearchEventReducer,
	directRoom_topic: directRoomEventReducer,
	roomInvite_topic: roomInviteEventReducer,
	room_topic: roomEventReducer,
	rtcRequest_topic: rtcRequestEventReducer,
	presence_topic: presenceEventReducer,
	presenceSessionId_topic: presenceSessionIdEventReducer,
	mtzplansubscription_topic: mtzPlanSubscriptionEventReducer
});

export default rootReducer; 