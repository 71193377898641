import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const requestFileAccess = (companyPrivateFileId) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}/accesses/request`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const deleteFileAccess = (companyPrivateFileId, userId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}/accesses/${userId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const acceptFileAccess = (companyPrivateFileId, userId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}/accesses/${userId}/accept`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};
