import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMyMtzSellerBalance = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-balance`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  getMyMtzSellerBalance
}