import commonUtil from "../common";
import { METRIC_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const getEmailCampaignTriggers = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/triggers?${queries.toString()}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailCampaignTriggerStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/triggers/stats?${queries.toString()}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailCampaignTriggerById = (id) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/triggers/${id}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};