import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCompanyExtraQuestionAnswers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-answers?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getCompanyExtraQuestionAnswerById = (companyExtraQuestionAnswerId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-answers/` + companyExtraQuestionAnswerId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyExtraQuestionAnswerId, headers);
};

const deleteCompanyExtraQuestionAnswerById = (companyExtraQuestionAnswerId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-answers/${companyExtraQuestionAnswerId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const createCompanyExtraQuestionAnswer = (newCompanyExtraQuestionAnswer) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-answers`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newCompanyExtraQuestionAnswer, headers);
};

const updateCompanyExtraQuestionAnswerById = (companyExtraQuestionAnswerId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-question-answers/${companyExtraQuestionAnswerId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export {
  getCompanyExtraQuestionAnswers,
  getCompanyExtraQuestionAnswerById,
  deleteCompanyExtraQuestionAnswerById,
  updateCompanyExtraQuestionAnswerById,
  createCompanyExtraQuestionAnswer
}