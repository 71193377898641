import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getHealthChecks = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-checks?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getHealthCheckStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-checks/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getHealthCheckById = healthCheckId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-checks/${healthCheckId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const createHealthCheck = healthCheck => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-checks`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, healthCheck, headers);
}

const updateHealthCheckById = (healthCheckId, changes) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-checks/${healthCheckId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, changes, headers);
}

const deleteHealthCheckById = healthCheckId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-checks/${healthCheckId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  getHealthChecks,
  getHealthCheckById,
  createHealthCheck,
  updateHealthCheckById,
  deleteHealthCheckById,
  getHealthCheckStats,
}