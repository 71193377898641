import React from 'react';
import ReactDOM from "react-dom";


function ConfirmPopup({ title, onYes, onNo, yesTitle, noTitle }) {
	const modalRef = React.useRef();

	React.useEffect(() => {
		if (!onYes) {
			throw new Error('Missing "onYes" method as attr');
		}

		if (!onNo) {
			throw new Error('Missing "onNo" method as attr');
		}

		if (!title) {
			throw new Error('Missing "title" method as attr');
		}

		if (modalRef && modalRef.current)
			modalRef.current.click();
	}, []);

	return (
		<div className='modal' id='modal-global-confirm'>
			<div className='modal-dialog'>
				<div className='modal-content'>
					<div className='modal-header'>
						<div className='d-flex w-100 align-items-center'>
							<h5 className={'mtz-h5 flex-fill'}></h5>
							<button data-toggle='modal' data-target='#modal-global-confirm' className='btn btn-sm rounded-circle' ref={modalRef}>
								<i className='fa fa-close'></i>
							</button>
						</div>
					</div>

					<div className='modal-body'>{title}</div>

					<div className='modal-footer'>
						<button className="btn 	btn-outline-primary mtz-btn " onClick={() => {
							modalRef.current.click();
							onNo();
						}}>{!noTitle ? 'No' : noTitle}</button>
						<button className="btn btn-primary mtz-btn text-white" onClick={() => {
							modalRef.current.click();
							onYes();
						}}>{yesTitle ? yesTitle : 'Yes'}</button>
					</div>
				</div>
			</div>
		</div>
	);
}

function createMtzConfirm(title, yesTitle, noTitle) {
	return new Promise((res, rej) => {
		let answer = null;
		let div = document.createElement('div');
		let root = document.getElementById('root');
		root.appendChild(div);

		const onYes = () => {
			ReactDOM.unmountComponentAtNode(div);
			answer = true;
			res(answer);
		};
		const onNo = () => {
			ReactDOM.unmountComponentAtNode(div);
			answer = false;
			res(answer);
		};

		ReactDOM.render(<ConfirmPopup title={title} yesTitle={yesTitle} noTitle={noTitle} onYes={onYes} onNo={onNo} />, div);
	});
};

export default createMtzConfirm;