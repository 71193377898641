import commonUtil from "../common";
import {
  ALERT_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = ALERT_SERVER_URL;

const getNotifications = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/notifications?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getNotificationStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/notifications/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getNotificationById = notificationId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/notifications/${notificationId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const createNotification = notification => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/notifications`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, notification, headers);
}

const updateNotificationById = (notificationId, changes) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/notifications/${notificationId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, changes, headers);
}

const deleteNotificationById = notificationId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/notifications/${notificationId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  getNotifications,
  getNotificationById,
  createNotification,
  updateNotificationById,
  deleteNotificationById,
  getNotificationStats,
}