import commonUtil from "../common";
import { deviceType } from 'react-device-detect';

import {
  METRIC_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = METRIC_SERVER_URL;

const getExtraDetails = async log => {
  const device = deviceType;
  return { ...log, device };
}

export const createCompanyWebsiteClickStat = async log => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-website-click-stats`;
  let headers = {
    "Content-Type": "application/json",
  };
  log = await getExtraDetails(log);
  return commonUtil.httpclient(method, url, log, headers);
}

export const getCompanyWebsiteClickStats = async queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-website-click-stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getCompanyWebsiteClickStatStats = async queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-website-click-stats/stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
}