import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import TabScrollButton from "@material-ui/core/TabScrollButton";

export const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ style: { transition: "none" } }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "9px",
    textTransform: "none",
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.01em",
    color: "#2B2B2B",
    gap: "20px",
    marginRight: theme.spacing(1),
  },
  selected: {
    gap: "20px",
    backgroundColor: "#3772ff",
    borderRadius: "16px",
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.01em",
    color: "#f9f9fb",
  },
}))((props) => <Tab disableRipple {...props} />);

export const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);
