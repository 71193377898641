import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import React from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import * as commonUtil from "../../../../utils/commonUtil";
import * as countryUtil from "../../../../utils/countryUtil";
import CompanyGroupCreateAlert from "../CompanyGroupCreateAlert";
import CompanyGroupProfileNameManager from "../CompanyGroupProfileNameManager";
import "./style.css";

const { companyService } = mtzApis;

export default function CompanyGroupBasicInfoViewEdit({ companyGroupId, profileName }) {
  let [companyGroup, setCompanyGroup] = React.useState(null);
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  let [company, setCompany] = React.useState(null);
  let [open, setOpen] = React.useState(false);
  let [changes, setChanges] = React.useState({});
  let [companyGroupTypes, setCompanyGroupTypes] = React.useState(null);
  let history = useHistory();
  const me = React.useContext(AuthContext);

  let isCompanyGroupAdmin =
    me &&
    companyGroup &&
    me.userId === companyGroup.ownerId;

  React.useEffect(() => {
    let isMounted = true;

    (async () => {
      let companyGroup;
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`profileName=${profileName}`)
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroup === null) {
        return;
      }

      let company = await companyService.getMyCompany();
      if (company) {
        let companyGroupMembers = await companyService.getCompanyGroupMembers(
          new URLSearchParams(`companyGroupIds=${companyGroup.id}&userIds=${me.userId}`)
        );
        let myCompanyGroupMember =
          companyGroupMembers && companyGroupMembers.length > 0 ? companyGroupMembers[0] : null;
        if (isMounted) setCompanyGroupMember(myCompanyGroupMember);
        if (isMounted) setCompany(company);
      }

      let companyGroupTypes = await companyService.getCompanyGroupTypes();
      if (isMounted) setCompanyGroupTypes(companyGroupTypes);
      if (isMounted) setCompanyGroup(companyGroup);
    })();

    return () => { isMounted = false; };
  }, [profileName, companyGroupId]);

  const handleClose = () => {
    setOpen(false);
    setChanges({});
  };

  const onUpdateHandler = async () => {
    if (changes.hasOwnProperty("name") && !changes.name.match(/^[a-z\d\-_\s]+$/i)) {
      alert("Name can only have alphanumeric characters!");
      return;
    }

    //remove null values from the changes
    Object.keys(changes).forEach((k) => changes[k] == null && delete changes[k]);

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCompanyGroupById(companyGroup.id, changes);
    mtzApis.stopSpinningIcon();
    if (updated) {
      setCompanyGroup(updated);
      history.replace(`/groups/${updated.profileName}`);
      handleClose();
    }
  };

  const getAddress = () => {
    let address = "";
    let city = !commonUtil.isBlank(companyGroup.city)
      ? address.concat(`${companyGroup.city + ", "}`)
      : "";
    let state = !commonUtil.isBlank(companyGroup.state)
      ? address.concat(`${companyGroup.state + ", "}`)
      : "";
    let country = !commonUtil.isBlank(companyGroup.country)
      ? address.concat(
        `${companyGroup.country ? countryUtil.from2CharToFullname(companyGroup.country) : ""}`
      )
      : "";

    address = city + state + country;
    address = address.replace(/,\s*$/, "");
    return address;
  };

  return (
    <React.Fragment>
      {companyGroup && (
        <Box className="d-flex flex-column mtz-gap-16">
          {isCompanyGroupAdmin && (
            <Box style={{ float: "right" }} mr={2}>
              <Button
                size="small"
                startIcon={<EditIcon fontSize="small" />}
                onClick={() => setOpen(true)}
              >
                <span className="company-group-info-edit-text">Edit group info</span>
              </Button>

              <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open || false}
                onClose={() => setOpen(false)}
              >
                <DialogTitle id="form-dialog-basic-info" className="text-center" disableTypography>
                  <h4 className="mtz-h4">Edit group info</h4>
                </DialogTitle>

                <DialogContent>
                  <CompanyGroupCreateAlert />
                  <br />
                  <div className="d-flex flex-column">
                    <label>
                      Name of group: <small className="text-danger">*required</small>
                    </label>
                    <TextField
                      fullWidth
                      required
                      onChange={(event) => setChanges({ ...changes, name: event.target.value })}
                      defaultValue={companyGroup.name}
                      placeholder="Group name"
                      variant="outlined"
                    />
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <CompanyGroupProfileNameManager
                      text={companyGroup.profileName}
                      onChange={(value) => setChanges({ ...changes, profileName: value })}
                    />
                  </div>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl required>
                        <label id="row-radio-buttons-group-label">Choose group type:</label>
                        <RadioGroup
                          row
                          aria-labelledby="row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(event) => setChanges({ ...changes, option: event.target.value })}
                          defaultValue={companyGroup.option}
                        >
                          <FormControlLabel
                            value="PUBLIC"
                            control={<Radio />}
                            label={
                              <span>
                                <PublicIcon className="mr-1" fontSize="small" />
                                Public
                              </span>
                            }
                          />

                          <FormControlLabel
                            value="PRIVATE"
                            control={<Radio />}
                            label={
                              <span>
                                <LockIcon className="mr-1" fontSize="small" />
                                Private
                              </span>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} container alignContent="center">
                      <FormControl required>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={companyGroup.isPostApprovalRequired}
                              onChange={(event) =>
                                setChanges({
                                  ...changes,
                                  isPostApprovalRequired: event.target.checked,
                                })
                              }
                              color="primary"
                            />
                          }
                          label="Post Approval Required"
                          labelPlacement="end"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className="d-flex flex-column">
                    {changes.companyGroupTypeId ? (
                      <div className="mb-1">
                        <span className="mtz-b1">
                          <b>Selected group type:&nbsp;</b>
                          {companyGroupTypes &&
                            companyGroupTypes.find((type) => type.id === changes.companyGroupTypeId)
                              .name}
                        </span>
                      </div>
                    ) : (
                      <div className="mb-1">
                        <span className="mtz-b1">
                          <b>Default group type:&nbsp;</b>
                          {companyGroupTypes &&
                            companyGroupTypes.find((type) => type.name === "Other").name}
                        </span>
                      </div>
                    )}
                    <div className="form-group">
                      <label className="form-group-label">Select group type</label>
                      <select
                        defaultValue={companyGroup.companyGroupTypeId || ""}
                        className="form-control"
                        onChange={(e) =>
                          setChanges({
                            ...changes,
                            companyGroupTypeId: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          ---Select type---
                        </option>
                        {companyGroupTypes &&
                          companyGroupTypes.length > 0 &&
                          companyGroupTypes.map((companyGroupType) => (
                            <option key={companyGroupType.id} value={companyGroupType.id}>
                              {companyGroupType.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <b>
                      <label>
                        Website URL:{" "}
                        <small className="text-danger">
                          E.g. https://example.com or http://example.com
                        </small>
                      </label>
                    </b>
                    <input
                      className="form-control"
                      placeholder="Website"
                      defaultValue={companyGroup.website || ""}
                      onChange={(e) => setChanges({ ...changes, website: e.target.value })}
                    />
                  </div>
                  <br />
                  <div className="d-flex flex-column">
                    <label>City:</label>
                    <input
                      className="form-control"
                      defaultValue={companyGroup.city || ""}
                      placeholder="City"
                      onChange={(e) => setChanges({ ...changes, city: e.target.value })}
                    />
                  </div>

                  <br />
                  <div className="d-flex flex-column">
                    <label>State:</label>
                    <input
                      className="form-control"
                      defaultValue={companyGroup.state || ""}
                      placeholder="State"
                      onChange={(e) => setChanges({ ...changes, state: e.target.value })}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="close-button"
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    disableElevation
                  >
                    <span className="close-button-text">Close</span>
                  </Button>
                  <Button
                    className="save-button"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => onUpdateHandler()}
                  >
                    <span className="save-button-text">Save</span>
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          <h3 className="mtz-h3">
            Group: <span className="text-primary">{companyGroup.name || ""}</span>
          </h3>

          <Box className="mr-3">
            {!companyGroup.asPrivate ? (
              <span>
                <span className="fa fa-unlock"></span> Public
              </span>
            ) : (
              <span>
                <span className="fa fa-lock"></span> Private
              </span>
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}
