import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getConnectionQR = (createParams = {}) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connection-qrs`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, createParams, headers);
};

export const acceptConnectionQR = (acceptParams) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/connection-qrs/accept`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, acceptParams, headers);
};
