import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const createTagAlert = tagAlert => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tag-alerts`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, tagAlert, headers);
};

const getTagAlerts = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tag-alerts?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getTagAlertStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tag-alerts/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, { spinnerOn: false }, headers);
};

const getTagAlertById = tagAlertId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tag-alerts/` + tagAlertId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, tagAlertId, headers);
}

const updateTagAlertById = (tagAlertId, tagAlert) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tag-alerts/${tagAlertId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, tagAlert, headers);
};

const deleteTagAlertById = tagAlertId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/tag-alerts/${tagAlertId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, tagAlertId, headers);
};

export {
  createTagAlert, deleteTagAlertById,
  getTagAlerts, getTagAlertById,
  getTagAlertStats, updateTagAlertById
};

