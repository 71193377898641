import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getUserExtraQuestionChoices = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-choices?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getUserExtraQuestionChoiceById = (userExtraQuestionChoiceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-choices/` + userExtraQuestionChoiceId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, userExtraQuestionChoiceId, headers);
};

export const deleteUserExtraQuestionChoiceById = (userExtraQuestionChoiceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-choices/${userExtraQuestionChoiceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const createUserExtraQuestionChoice = (newUserExtraQuestionChoice) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-choices`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newUserExtraQuestionChoice, headers);
};

export const updateUserExtraQuestionChoiceById = (userExtraQuestionChoiceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-question-choices/${userExtraQuestionChoiceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
