import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCompanies = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getCompanyCompletionScores = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/completion`;
  let params = {};
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getCompanyById = companyId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/` + companyId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyId, headers);
}

const getCompanyStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, { spinnerOn: false }, headers);
};

const createCompany = company => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, company, headers);
};

const getMyCompany = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company`;
  let params = {};
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const updateCompanyById = (companyId, company) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/${companyId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, company, headers);
};

const deleteCompanyById = companyId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/${companyId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyId, headers);
};

const updateCompanyLogoBannerById = (companyId, formData) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/${companyId}/logo-banner`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, formData, headers);
}

export {
  createCompany, deleteCompanyById, getCompanies, getCompanyById, getCompanyCompletionScores,
  getCompanyStats, getMyCompany, updateCompanyById, updateCompanyLogoBannerById
};

