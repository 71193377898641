export const MY_NETWORK = "/me/network";
export const MY_CONNECTIONS = "/me/connections";
export const MY_FOLLOWINGS = "/me/followings";
export const MY_COMPANY_WATCHLISTS = "/me/company-watchlists";
export const MY_GROUPS = "/me/groups";
export const MY_PRODUCT_WATCHLISTS = "/me/product-watchlists";
export const GLOBAL_CALENDAR = "/me/global-calendar";
export const ADMIN_SPONSORSHIP_SUBSCRIPTION_CREATE_STATS =
  "/mtz-admin/stats/sponsorship-subscriptions-create";
export const MY_ADVERTISEMENTS = "/me/advertisement";
export const MY_ORGANIC_LISTINGS = `${MY_ADVERTISEMENTS}/organic-listings`;
export const MY_AD_TEMPALTES = `${MY_ADVERTISEMENTS}/ad-templates`;