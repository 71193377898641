import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getFeedLikes = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-likes?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedLikeStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-likes/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedLikeById = (feedLikeId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-likes/${feedLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createFeedLike = (feedLike) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-likes`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, feedLike, headers);
};

export const deleteFeedLikeById = (feedLikeId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-likes/${feedLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateFeedLikeById = (feedLikeId, feedLike) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-likes/${feedLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, feedLike, headers);
};
