import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;
export const createMtzPointBalance = mtzPointBalance => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-balances`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, mtzPointBalance, headers);
}
export const getMyPointBalance = query => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-balance`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}
export const getMtzPointBalances = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-balances?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}