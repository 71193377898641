import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getFeedCommentReplies = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-replies?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedCommentReplyStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-replies/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedCommentReplyById = (feedCommentReplyId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-replies/${feedCommentReplyId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createFeedCommentReply = (comment) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-replies`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, comment, headers);
};

export const deleteFeedCommentReplyById = (feedCommentReplyId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-replies/${feedCommentReplyId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateFeedCommentReplyById = (feedCommentReplyId, comment) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-replies/${feedCommentReplyId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, comment, headers);
};
