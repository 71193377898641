import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

export const getSyncedUsers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-users?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getSyncedUsersStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-users/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};