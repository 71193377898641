import { CardActions } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import React from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { NavLink } from "react-router-dom";
import mtzApis from "../../../../services";
import * as countryUtil from "../../../../utils/countryUtil";
import ChatButton2 from '../../../chat-service/messages/ChatButton2';
import "./style.css";

const { companyService } = mtzApis;

export default function CompanyDetailCard({ companyId, profileName }) {
  let [companySearch, setCompanySearch] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      let company;
      if (companyId) {
        const companies = await companyService.getCompanySearches(
          new URLSearchParams(`companyIds=${companyId}`)
        );
        company = companies.length > 0 ? companies[0] : null;
      }
      if (profileName) {
        const companies = await companyService.getCompanySearches(
          new URLSearchParams(`profileName=${profileName}`)
        );
        company = companies.length > 0 ? companies[0] : null;
      }

      if (company === null) return;
      setCompanySearch(company);
    })();
  }, [companyId, profileName]);

  const getPhoneNumber = (company) =>
    company.phone && company.phone.length !== 0 ? "+" + company.countryCode + company.phone : "";

  return (
    <div>
      {companySearch && (
        <Card elevation={0} className="company-detail">
          <CardHeader
            title={<NavLink className="company-detail-heading" to={`/profile/${companySearch.company.profileName}`}>{companySearch.company.name}</NavLink>}
            subheader={
              companySearch.followCount > 0 && (
                <small className="company-follow-count">
                  {companySearch.followCount} people follow us.
                </small>
              )
            }
          />
          <CardContent>
            {companySearch.company.website && (
              <Box mb={1}>
                <PublicIcon style={{ marginRight: "2px" }} className="text-secondary" />
                &nbsp;
                <a className="company-website" target="_blank" rel="noopener nofollow" href={companySearch.company.website}>
                  Visit website
                </a>
              </Box>
            )}

            {companySearch.company.phone && (
              <Box mb={1}>
                <PhoneIcon style={{ marginRight: "2px" }} className="text-secondary" />
                <a href={`tel:${getPhoneNumber(companySearch.company)}`} className="company-phone">
                  {formatPhoneNumberIntl(getPhoneNumber(companySearch.company))}
                </a>
              </Box>
            )}

            {companySearch.company.address && (
              <Box>
                <LocationOnIcon className="text-secondary" />
                <span className="company-address">
                  {`${companySearch.company.address}, ${companySearch.company.city || ""}, ${companySearch.company.state || ""
                    }, ${companySearch.company.country
                      ? countryUtil.from2CharToFullname(companySearch.company.country)
                      : ""
                    }
                    ${companySearch.company.zipCode || ""}`}
                </span>
              </Box>
            )}
          </CardContent>
          <CardActions>
            <ChatButton2 targetUserId={companySearch.company.ownerId} />
          </CardActions>
        </Card>
      )}
    </div>
  );
}
