import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyGroupUserPref = (companyGroupUserPref) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-user-prefs`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupUserPref, headers);
};

export const deleteCompanyGroupUserPrefById = (companyGroupUserPrefId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-user-prefs/${companyGroupUserPrefId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupUserPrefById = (companyGroupUserPrefId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-user-prefs/${companyGroupUserPrefId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupUserPrefs = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-user-prefs?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupUserPrefStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-user-prefs/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCompanyGroupUserPrefById = (companyGroupUserPrefId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-user-prefs/${companyGroupUserPrefId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
