import commonUtil from "../common";
import {
  ALERT_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = ALERT_SERVER_URL;

export const getEmailDeliveries = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-deliveries?${queries.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getEmailDeliveryStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-deliveries/stats?${queries.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const deleteEmailDeliveryByFilter = queries => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-deliveries?${queries.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getUserEmailDeliveries = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-deliveries/users?${queries.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getUserEmailDeliveryStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-deliveries/users/stats?${queries.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}