import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const createRfqImage = (formData) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfqs/images`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, formData, headers);
}

const deleteRfqImageById = (id) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfqs/images/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
};

const getRfqImages = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfqs/images?${decodeURIComponent(queries)}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
};

export {
    createRfqImage, deleteRfqImageById, getRfqImages
};
