import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getRfiTypes = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfiRequestTypes?${decodeURIComponent(queries)}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, {}, headers);
};



const getRfiTypeById = (id) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfiRequestTypes/` + id;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, id, headers);
};



export {
    getRfiTypes,

    getRfiTypeById


}