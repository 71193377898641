import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const createStripeAccountDashboardLink = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/stripe-account-dashboard-links`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export {
  createStripeAccountDashboardLink
}