import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const inviteCompanyGroupMember = (companyGroupMember) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/invite-company`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMember, headers);
};

export const joinCompanyGroupMember = (companyGroupMember) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/join`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMember, headers);
};

export const acceptRequestCompanyGroupMember = (companyGroupMemberId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/${companyGroupMemberId}/accept-request`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const acceptInviteCompanyGroupMember = (companyGroupMemberId, acceptParams = {}) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/${companyGroupMemberId}/accept-invite`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, acceptParams, headers);
};

export const inviteCompanyGroupMemberByEmail = (companyGroupMember) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-members/invite-email`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMember, headers);
};
