import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCgResource = (cgResource) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-resources`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, cgResource, headers);
};

export const deleteCgResourceById = (cgResourceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-resources/${cgResourceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCgResourceById = (cgResourceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-resources/${cgResourceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCgResources = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-resources?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCgResourceStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-resources/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCgResourceById = (cgResourceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-resources/${cgResourceId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};
