import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyGroup = (companyGroup) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroup, headers);
};

export const deleteCompanyGroupById = (companyGroupId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/${companyGroupId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupById = (companyGroupId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/${companyGroupId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroups = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCompanyGroupById = (companyGroupId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/${companyGroupId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const updateCompanyGroupBannerById = (companyGroupId, params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/${companyGroupId}/banner`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const updateCompanyGroupLogoById = (companyGroupId, params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/${companyGroupId}/logo`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
