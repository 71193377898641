import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGMemberRequest = (cgMemberRequest) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-requests`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, cgMemberRequest, headers);
};

export const deleteCGMemberRequestById = (cgMemberRequestId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-requests/${cgMemberRequestId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getCGMemberRequestById = (cgMemberRequestId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-requests/${cgMemberRequestId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getCGMemberRequests = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-requests?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCGMemberRequestStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-requests/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCGMemberRequestById = (cgMemberRequestId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-requests/${cgMemberRequestId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};
