import React from "react";

import ImageForm from "../../../shared2/LogoImageForm";

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

export default function CompanyGroupLogoManager({
  onUpload,
  companyGroupId,
  profileName,
  width,
}) {
  let [company, setCompany] = React.useState(null);
  let [companyGroup, setCompanyGroup] = React.useState(null);
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  let [updatedHeight, setUpdatedHeight] = React.useState(null);

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      let companyGroup;
      if (companyGroupId) {
        const companyGroups = await companyService.getCompanyGroups(
          `companyGroupIds=${companyGroupId}`
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(`profileName=${profileName}`);
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroup === null) return;
      if (isMounted) setCompanyGroup(companyGroup);

      let company = await companyService.getMyCompany();
      if (company) {
        let companyGroupMembers = await companyService.getCompanyGroupMembers(
          new URLSearchParams(
            `companyGroupIds=${companyGroup.id}&userIds=${me.userId}`
          )
        );
        if (companyGroupMembers && companyGroupMembers.length > 0) {
          if (isMounted) setCompanyGroupMember(companyGroupMembers[0]);
        }
        if (isMounted) setCompany(company);
      }
    })();

    return () => { isMounted = false; };
  }, [companyGroupId, profileName]);

  const uploadLogo = async (fileInfo) => {
    mtzApis.startSpinningIcon();
    const data = new FormData();
    data.append("logoFile", new File([fileInfo.blob], "logoFile." + fileInfo.ext));
    companyGroup = await companyService.updateCompanyGroupLogoById(companyGroup.id, data);
    mtzApis.stopSpinningIcon();
    if (companyGroup) {
      setCompanyGroup(companyGroup);
      if (onUpload) onUpload(companyGroup.logoUrl);
    }
  };

  return (
    <React.Fragment>
      {companyGroup && (
        <div id="mtz-company-group-logo">
          <ImageForm
            height={width}
            width={width}
            editable={
              company &&
              companyGroupMember &&
              companyGroupMember.companyGroupId === companyGroup.id &&
              companyGroupMember.role === "ADMIN"
            }
            imageUrl={companyService.getBackendHost() + (companyGroup.logoUrl || "")}
            onUpload={uploadLogo}
          />
        </div>
      )}
    </React.Fragment>
  );
}
