import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getAdCampaignClickCharges = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/click-charges?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignClickChargeStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/click-charges/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignClickChargeById = (adCampaignClickChargeId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/click-charges/${adCampaignClickChargeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createAdCampaignClickCharge = (adCampaign) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/click-charges`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, adCampaign, headers);
};
