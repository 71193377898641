import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import GoogleMapReact from "google-map-react";
import { GoogleApiWrapper } from "google-maps-react";
import React from "react";
import Geocode from "react-geocode";
import PhoneInput, { formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PlacesAutocomplete from "react-places-autocomplete";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import { GOOGLE_API_KEY } from "../../../../services/config";
import * as countryUtil from "../../../../utils/countryUtil";
import OnlineStatus from "../../../shared2/OnlineStatus";

const { companyService } = mtzApis;

function CompanyBasicInfoViewEdit({ companyId, profileName }) {
  let params = new URLSearchParams(window.location.search);
  let filter = { ...Object.fromEntries(params) }
  let [company, setCompany] = React.useState(null);
  let [openCompInfo, setOpenCompInfo] = React.useState(filter.selectedDialog === "COMPANY_INFO" ? true : false);
  let [changes, setChanges] = React.useState({});
  let [enteredAddress, setEnteredAddress] = React.useState("");
  let [googleAddress, setGoogleAddress] = React.useState({});
  let [openMap, setOpenMap] = React.useState(false);
  let [companySearch, setCompanySearch] = React.useState(null);
  let [anchorEl, setAnchorEl] = React.useState(null);
  let [score, setScore] = React.useState(null);



  const me = React.useContext(AuthContext);

  const getGoogleAddress = async (address) => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage("en");

    try {
      let response = await Geocode.fromAddress(address);
      let googleAddress = {
        country: "",
        zipCode: "",
        state: "",
        city: "",
        address: "",
      };
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        let componentName = response.results[0].address_components[i].types[0];
        switch (componentName) {
          case "country":
            googleAddress.country = response.results[0].address_components[i].short_name;
            break;
          case "postal_code":
            googleAddress.zipCode = response.results[0].address_components[i].short_name;
            break;
          case "administrative_area_level_1":
            googleAddress.state = response.results[0].address_components[i].short_name;
            break;
          case "locality":
            googleAddress.city = response.results[0].address_components[i].short_name;
            break;
          case "route":
            googleAddress.streetName = response.results[0].address_components[i].short_name;
            break;
          case "street_number":
            googleAddress.streetNumber = response.results[0].address_components[i].short_name;
            break;
        }
      }

      const { lat, lng } = response.results[0].geometry.location;
      googleAddress.lat = lat;
      googleAddress.lng = lng;

      googleAddress.address =
        (googleAddress.streetNumber || "") + " " + (googleAddress.streetName || "");

      delete googleAddress["streetNumber"];
      delete googleAddress["streetName"];

      return googleAddress;
    } catch (e) {
      console.log(e);
    }
  };

  const getDefaultGoogleAddress = (company) => {
    let googleAddress = {
      country: company.country || "",
      zipCode: company.zipCode || "",
      state: company.state || "",
      city: company.city || "",
      address: company.address || "",
    };
    return googleAddress;
  };

  React.useEffect(() => {
    (async () => {
      let company;
      if (companyId) {
        const companies = await companyService.getCompanies(
          new URLSearchParams(`companyIds=${companyId}`)
        );
        company = companies.length > 0 ? companies[0] : null;
      }
      if (profileName) {
        const companies = await companyService.getCompanies(
          new URLSearchParams(`profileName=${profileName}`)
        );
        company = companies.length > 0 ? companies[0] : null;
      }

      if (company) {
        // load googleAddr if not exist
        if (!company.lat || !company.lng) {
          const googleAddress = await getGoogleAddress(`${company.address || ''}, ${company.city || ''}, ${company.state || ''}, ${company.country || ''} ${company.zipCode || ''}`);
          if (googleAddress) {
            company.lat = googleAddress.lat;
            company.lng = googleAddress.lng;
          }
        }

        // get my score
        let userIds = company.ownerId.split()
        let scores = await companyService.getMtzScores(new URLSearchParams(`userIds=${userIds}`))
        setScore(scores[0])

        let companySearches = await companyService.getCompanySearches(
          new URLSearchParams(`profileName=${company.profileName}`)
        );
        let companySearch = companySearches && companySearches.length > 0 ? companySearches[0] : null;
        setCompanySearch(companySearch)

        setCompany(company);
        setGoogleAddress(getDefaultGoogleAddress(company));
      }
    })();
  }, [companyId, profileName]);

  const updateCompany = async () => {
    if (changes.phone && changes.phone.length !== 0) {
      const parsedPhone = parsePhoneNumber(changes.phone);
      changes.phone = parsedPhone.nationalNumber;
      changes.countryCode = parsedPhone.countryCallingCode;
    }

    delete googleAddress.lat;
    delete googleAddress.lng;
    changes = { ...changes, ...googleAddress };

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCompanyById(company.id, changes);
    mtzApis.stopSpinningIcon();

    if (updated) {
      setOpenCompInfo(false);
      const googleAddress = await getGoogleAddress(`${updated.address || ''}, ${updated.city || ''}, ${updated.state || ''}, ${updated.country || ''} ${updated.zipCode || ''}`);
      updated.lat = googleAddress.lat;
      updated.lng = googleAddress.lng;
      setCompany(updated);
      setEnteredAddress("");
      setGoogleAddress(getDefaultGoogleAddress(updated));
      setChanges({});
    }
  };

  const handleAddressChange = (address) => {
    setEnteredAddress(address);
  };

  const handleAddressSelect = async (address) => {
    let googleAddress = await getGoogleAddress(address);
    setEnteredAddress(address);
    setGoogleAddress(googleAddress);
  };

  const onReportHandler = async (companySearch, report) => {
    mtzApis.startSpinningIcon();
    let reported = await companyService.createCompanySearchReport(companySearch.id, report);
    mtzApis.stopSpinningIcon();
    if (reported) {
      window.location.href = "/";
    }
  };

  const getPhoneNumber = (company) =>
    company.phone && company.phone.length !== 0 ? "+" + company.countryCode + company.phone : "";

  const renderAddress = () => {
    return (
      <React.Fragment>
        <div>
          <a data-toggle='modal' data-target='#modal-open-map' className="d-flex align-items-center btn btn-sm btn-link " href='#'>
            <span className="fa fa-map"></span>
            &nbsp;
            <span className="">
              {company.address
                ? `${company.city || ""}, ${company.state || ""}, ${company.country ? countryUtil.from2CharToFullname(company.country) : ""
                }`
                : "Address is not available"}
            </span>
          </a>
        </div>

        <div className="modal" id='modal-open-map'>
          <div className="modal-xl modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex">
                <h5 className="mtz-h5 flex-fill">Company Address on Google Map:</h5>

                <button data-dismiss='modal' className="rounded btn btn-sm">
                  <span className="fa fa-close"></span>
                </button>
              </div>

              <div className="modal-body d-flex flex-column mtz-gap-16">
                <div>
                  Address:
                  &nbsp;
                  <a target="_black" href={
                    'https://google.com/maps/place/' +
                    (company.address
                      ? `${company.address}, ${company.city || ""}, ${company.state || ""}, ${company.country ? countryUtil.from2CharToFullname(company.country) : ""
                      } ${company.zipCode || ""}`
                      : "Address is not available")
                  }
                    className="mtz-h5 text-primary">
                    {company.address
                      ? `${company.address}, ${company.city || ""}, ${company.state || ""}, ${company.country ? countryUtil.from2CharToFullname(company.country) : ""
                      } ${company.zipCode || ""} (Open on Google Map)`
                      : "Address is not available"}
                  </a>
                </div>

                <div className="w-100 vh-75">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_API_KEY, region: "cn" }}
                    defaultCenter={{
                      lat: parseFloat(company.lat || 0),
                      lng: parseFloat(company.lng || 0),
                    }}
                    defaultZoom={12}
                  >
                    <Marker lat={company.lat} lng={company.lng}
                      text={<span className="mtz-h3 text-danger fa fa-map-pin"></span>} />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handleMoreHorizClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const noteButtonOpen = Boolean(anchorEl);

  return (
    <React.Fragment>
      {company && (
        <Box className="mtz-px-16">
          {me && company && me.userId === company.ownerId && (
            <Box style={{ float: "right" }} mr={2}>
              <button className="btn btn-sm btn-link"
                onClick={() => setOpenCompInfo(true)}>
                <span className="fa fa-edit"></span> Edit company info
              </button>
            </Box>
          )}
          <Typography gutterBottom className="mtz-h4">
            <OnlineStatus userId={company.ownerId} children={<b className="text-break">{company.name || ""}</b>} />
          </Typography>

          <div className="d-flex flex-wrap align-items-center mtz-gap-4">
            {company.website && (
              <a
                className="btn btn-sm btn-link text-nowrap"
                target="_blank"
                href={
                  (company && company.website && !company.website.startsWith("http")
                    ? "https://"
                    : "") + (company && company.website)
                }
              >
                <span className="fa fa-globe"></span> {company.website.startsWith("http")
                  ? company.website.replace("https://", "").replace("http://", "")
                  : company.website}
              </a>
            )}
            |
            {company.phone && company.phone.trim() && formatPhoneNumberIntl(getPhoneNumber(company)) && (
              <a href={`tel:${getPhoneNumber(company)}`} className="text-nowrap btn btn-sm btn-link">
                <span className="fa fa-phone"></span> {formatPhoneNumberIntl(getPhoneNumber(company))}
              </a>
            )}
            |
            <span className="">{renderAddress()}</span>
            |
            <span className="btn btn-sm text-nowrap">Score : {score ? score.points : 0}</span>
          </div>

          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={openCompInfo}
            onClose={() => setOpenCompInfo(false)}
          >
            <DialogTitle id="form-dialog-basic-info" className="text-center" disableTypography>
              <h4 className="company-edit-dialog-header">Edit company info</h4>
            </DialogTitle>

            <DialogContent>
              <div className="my-4">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <b>
                      <label>Company name</label>
                    </b>

                    <TextField
                      variant="outlined"
                      required
                      onChange={(event) => setChanges({ ...changes, name: event.target.value })}
                      fullWidth={true}
                      defaultValue={company.name || ""}
                      placeholder="Company name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b>
                      <label>Company email</label>
                    </b>

                    <TextField
                      variant="outlined"
                      required
                      onChange={(event) => setChanges({ ...changes, email: event.target.value })}
                      fullWidth={true}
                      defaultValue={company.email || ""}
                      placeholder="Company email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b>
                      <label>
                        Website URL:{" "}
                        <small className="text-danger">
                          E.g. https://example.com or http://example.com
                        </small>
                      </label>
                    </b>

                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Website"
                      onChange={(event) => setChanges({ ...changes, website: event.target.value })}
                      value={(() => {
                        if (changes.website === "" || (!!changes.website && changes.website !== ""))
                          return changes.website;
                        if (!!company.website) return company.website;
                        return "";
                      })()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b>
                      <label>Phone number</label>
                    </b>

                    {company && company.country && (
                      <PhoneInput
                        className="form-control"
                        style={{ height: "40px" }}
                        international
                        title=""
                        limitMaxLength={true}
                        countryCallingCodeEditable={false}
                        defaultCountry={company.country || "US"}
                        value={`${getPhoneNumber(company)}`}
                        onChange={(value) => setChanges({ ...changes, phone: value })}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>

              <Box className="border p-2 rounded">
                <PlacesAutocomplete
                  value={enteredAddress}
                  onChange={handleAddressChange}
                  onSelect={handleAddressSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Box>
                      <Box>
                        <Typography variant="subtitle2">
                          <b>Type your address:</b>
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          autoFocus
                          {...getInputProps({
                            placeholder: "Search address on Google...",
                            className: "location-search-input",
                          })}
                        />
                      </Box>
                      <Box pl={2} className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "bg-light text-secondary"
                            : "bg-white text-dark";

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className: className + " mtz-cursor-pointer",
                              })}
                              key={suggestion.placeId}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </PlacesAutocomplete>
              </Box>

              {googleAddress && JSON.stringify(googleAddress) !== "{}" && (
                <React.Fragment>
                  <hr />
                  <Box>
                    {googleAddress &&
                      JSON.stringify(googleAddress) !== "{}" &&
                      googleAddress.country && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <b>Address:</b>
                            <input
                              onChange={(e) =>
                                setGoogleAddress({ ...googleAddress, address: e.target.value })
                              }
                              className="w-100 form-control"
                              value={googleAddress.address || ""}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <b>City:</b>
                            <input
                              onChange={(e) =>
                                setGoogleAddress({ ...googleAddress, city: e.target.value })
                              }
                              className="w-100 form-control"
                              value={googleAddress.city || ""}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <b>State:</b>
                            <input
                              onChange={(e) =>
                                setGoogleAddress({ ...googleAddress, state: e.target.value })
                              }
                              className="w-100 form-control"
                              value={googleAddress.state || ""}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <b>Zipcode:</b>
                            <input
                              onChange={(e) =>
                                setGoogleAddress({ ...googleAddress, zipCode: e.target.value })
                              }
                              className="w-100 form-control"
                              value={googleAddress.zipCode || ""}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <b>Country:</b>{" "}
                            {googleAddress.country && googleAddress.country.trim()
                              ? countryUtil.from2CharToFullname(
                                googleAddress.country && googleAddress.country.trim()
                              )
                              : ""}
                          </Grid>
                        </Grid>
                      )}
                  </Box>
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                className="close-button"
                onClick={() => setOpenCompInfo(false)}
                variant="outlined"
                color="primary"
                disableElevation
              >
                <span className="close-button-text">Close</span>
              </Button>
              <Button
                className="save-button"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => updateCompany()}
              >
                <span className="save-button-text">Save</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </React.Fragment>
  );
}

const Marker = ({ text }) => <div>{text}</div>;

const CompanyBasicInfoViewEditWrapper = (props) => (
  <AuthContext.Consumer>
    {(val) => <CompanyBasicInfoViewEdit {...props} me={val} />}
  </AuthContext.Consumer>
);

export default GoogleApiWrapper({
  apiKey: "AIzaSyAJ3PgXSBGIrZh_ii7gnJD_rrP-Y7-1d0A",
})(CompanyBasicInfoViewEditWrapper);
