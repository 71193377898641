import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCompanyPosts = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-posts?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyPostStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-posts/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyPostById = (companyGroupPostId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCompanyPost = (companyGroupPost) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-posts`;
  let headers = {};
  return commonUtil.httpclient(method, url, companyGroupPost, headers);
};

export const deleteCompanyPostById = (companyGroupPostId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCompanyPostById = (companyGroupPostId, companyGroupPost) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupPost, headers);
};