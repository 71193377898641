import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyName = (companyName) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-names`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyName, headers);
};

export const deleteCompanyNameById = (companyNameId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-names/${companyNameId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyNameById = (companyNameId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-names/${companyNameId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyNames = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-names?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCompanyNameById = (companyNameId, companyName) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-names/${companyNameId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyName, headers);
};

export const getCompanyNameStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-names/stats?${decodeURIComponent(
      queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
  };
