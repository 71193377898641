import React from "react";
import {
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer
} from "recharts";

export default function AreaChartWrapper({ data, xAxis,name }) {
  return (
    <div style={{textAlign:"center"}}>
      <h4>Area Chart</h4>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart width={400} height={250} data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis} />
          <YAxis dataKey="count" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            name={name}
            dataKey="count"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
