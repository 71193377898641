import React from 'react';

import ImageForm from '../../../shared2/LogoImageForm';

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

function CompanyLogoManager({ me, onUpload, companyId, profileName, width }) {
	let [company, setCompany] = React.useState(null);

	React.useEffect(() => {
		(async () => {
			if (companyId) {
				const companies = await companyService.getCompanies(`companyId=${companyId}`);
				if (companies) {
					company = companies[0];
					setCompany(company);
				}
			}
			if (profileName) {
				const companies = await companyService.getCompanies(`profileName=${profileName}`);
				if (companies) {
					company = companies[0];
					setCompany(company);
				}
			}
		})();
	}, [companyId, profileName]);

	const uploadLogo = async fileInfo => {
		mtzApis.startSpinningIcon();
		const data = new FormData()
		data.append("logoFile", new File([fileInfo.blob], 'logoFile.' + fileInfo.ext))
		company = await companyService.updateCompanyLogoBannerById(
			company.id,
			data
		);
		mtzApis.stopSpinningIcon();
		if (company) {
			setCompany(company);
			if (onUpload)
				onUpload(company.logoUrl);
		}
	}

	return (
		<React.Fragment>
			{
				company &&
				<div id="mtz-company-logo">
					<ImageForm height={width} width={width}
						editable={company && me && me.userId === company.ownerId}
						imageUrl={company && company.logoUrl ? (company.logoUrl.startsWith('http') ? company.logoUrl : companyService.getBackendHost() + company.logoUrl) : ""}
						onUpload={uploadLogo}
					/>
				</div>
			}
		</React.Fragment>
	);
}

export default props => (
	<AuthContext.Consumer>
		{
			val => <CompanyLogoManager {...props} me={val} />
		}
	</AuthContext.Consumer>
);