import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getProductMenus = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getProductMenuStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus/stats?${decodeURIComponent(queries)}`;
  let headers = {"Content-Type": "application/json",};
  return commonUtil.httpclient(method, url, null, headers);
}

const getProductMenuById = productMenuIdId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus/${productMenuIdId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const createProductMenu = productMenuId => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, productMenuId, headers);
}

const deleteProductMenuById = productMenuId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus/${productMenuId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const deleteProductMenus = queries => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const updateProductMenuById = (productMenuId, productMenu) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menus/${productMenuId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, productMenu, headers);
}

export {
  getProductMenus,
  getProductMenuById,
  createProductMenu,
  deleteProductMenuById,
  deleteProductMenus,
  updateProductMenuById,
  // getProductMenuStats
}