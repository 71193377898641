import React from "react";
import { withStyles } from "@material-ui/core/styles";
import mtzApis from "../../../services";
import AuthContext from "../../../AuthContext";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";

const { alertService } = mtzApis;

const styles = (theme) => ({
  onlineBadge: {
    backgroundColor: "green",
    color: "white",
    cursor: "pointer",
    height: "12px",
    minWidth: "auto",
    width: "5px",
  },
  offlineBadge: {
    backgroundColor: "gray",
    color: "white",
    cursor: "pointer",
    height: "12px",
    minWidth: "auto",
    width: "5px",
  },
});

function OnlineStatus({ userId, children, classes, newPresenceEvent }) {
  let [isOnline, setIsOnline] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      const presences = await alertService.getPresences(new URLSearchParams(`userIds=${userId}`));
      let isOnline = presences && presences.length > 0 ? true : false;
      setIsOnline(isOnline);
    })();

    if (newPresenceEvent && JSON.stringify(newPresenceEvent) !== "{}") {
      if (userId === newPresenceEvent.resource.userId) {
        if (newPresenceEvent.action === "DELETED") setIsOnline(false);
        else setIsOnline(true);
      }
    }
  }, [newPresenceEvent]);

  return (
    <Badge
      overlap="rectangular"
      classes={{
        badge: isOnline || userId === me.userId ? classes.onlineBadge : classes.offlineBadge,
      }}
      badgeContent={
        <Tooltip title={isOnline ? "online" : "offline"}>
          <span className="w-100 h-100">&nbsp;</span>
        </Tooltip>
      }
    >
      {children}
    </Badge>
  );
}

const stateToProps = (state) => ({
  newPresenceEvent: state.presence_topic.new_uievent,
});

const OnlineStatusContainer = connect(stateToProps)(OnlineStatus);

export default withStyles(styles)(OnlineStatusContainer);
