import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyFile = companyFile => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-files`;
  let headers = {};
  return commonUtil.httpclient(method, url, companyFile, headers);
}

export const deleteCompanyFileById = companyFileId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-files/${companyFileId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyFileById = companyFileId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-files/${companyFileId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyFiles = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-files?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}


export const getCompanyFileStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-files/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

export const updateCompanyFileById = (companyFileId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-files/${companyFileId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
}