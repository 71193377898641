import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const testSchema = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas/test`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
}

export const createEmailSchema = emailSchema => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, emailSchema, headers);
}

export const deleteEmailSchemaById = emailSchemaId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas/${emailSchemaId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getEmailSchemaById = emailSchemaId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas/${emailSchemaId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getEmailSchemas = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getEmailSchemaStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateEmailSchemaById = (emailSchemaId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-schemas/${emailSchemaId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}