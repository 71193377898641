import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Moment from 'react-moment';
import mtzApis from '../../../../services';
import { PAYMENT_SERVER_URL } from '../../../../services/config';
import { getMyMtzCustomer } from '../../../../services/payment/mtzCustomerApi';

const { paymentService } = mtzApis;

let stripePromise = null;
if (PAYMENT_SERVER_URL.includes('localhost'))
  stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');
else if (PAYMENT_SERVER_URL.includes('mytradezone.com'))
  stripePromise = loadStripe('pk_live_51LuGhqH6fkJtSoVxsSVoDiSV7KpR3EhT0rCBwCHZmZVhgeBm140UiXiHstO3GG5F57KxGqopWT2c8bf7bVMrr2y700fViKFBYd');
else
  stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');

function CardManager({ onChange }) {
  let [customer, setCustomer] = React.useState();
  let [cards, setCards] = React.useState();
  let [openCardForm, setOpenCardForm] = React.useState(false);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    customer = await getMyMtzCustomer();

    if (customer) {
      setCustomer(customer);

      cards = await paymentService.getCards();
      if (cards) {
        cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
        setCards(cards);
      }
    }
  }

  const onNewCard = newCard => {
    if (cards) {
      cards = [newCard, ...cards];
      cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
      setCards(cards);
      setOpenCardForm(false);
      if (onChange) onChange(cards);
    }
  };

  const onDelCard = async id => {
    const ok = await window.createMtzConfirm('Are you sure?');
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await paymentService.deleteCardById(id);
    mtzApis.stopSpinningIcon();

    if (del) {
      alert('Card deleted');
      cards = cards.filter(c => c.id !== del.id);
      cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
      setCards(cards);
      if (onChange) onChange(cards);
    }
  };

  const onCardDefault = async card => {
    const ok = await window.createMtzConfirm('Are you sure?');
    if (!ok) return;


    mtzApis.startSpinningIcon();
    let updated = await paymentService.updateCardById(card.id, { asDefault: true });
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert('Card updated');
      cards = cards.map(c => {
        if (c.id !== updated.id) {
          if (c.asDefault)
            c.asDefault = false;
          return c;
        }

        return updated;
      });
      cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
      setCards(cards);
      if (onChange) onChange(cards);
    }
  };

  return (
    <div className='mtz-p-16 mtz-rounded-16 bg-white d-flex flex-column mtz-gap-16'>
      {customer === null && 'Loading...'}

      {
        customer &&
        <>
          <div className='d-flex flex-column mtz-gap-16'>
            <div className='mtz-h5'>Cards:</div>

            <div className='w-100 text-right'>
              <button onClick={() => setOpenCardForm(true)}
                className='btn btn-sm btn-primary mtz-btn'>
                Add new card +</button>

              <Dialog open={openCardForm} fullWidth maxWidth='md'>
                <DialogTitle>
                  <div className='d-flex'>
                    <span className='mtz-h5 flex-fill'>Add New Card</span>
                    <IconButton onClick={() => setOpenCardForm(false)}><CloseIcon /></IconButton>
                  </div>
                </DialogTitle>

                <DialogContent>
                  <Elements stripe={stripePromise}>
                    <CardForm onCreate={onNewCard} />
                  </Elements>
                </DialogContent>
              </Dialog>
            </div>

            {cards === null && 'Loading...'}

            {
              cards && cards.length === 0 && (
                <>
                  <div>You have no card.</div>
                </>
              )
            }

            {
              cards && cards.length > 0 &&
              <div className='d-flex flex-column mtz-gap-8'>
                {
                  cards.map(c => (
                    <div className='p-2 mtz-rounded-16 border align-items-center' key={c.id}>
                      <div className='d-flex flex-gap-16'>
                        <div className='flex-fill'>
                          <b>Card Name: {c.name}</b>
                          &nbsp; - &nbsp;
                          <b className='flex-fill'>Last 4 digits: {c.last4 ? c.last4 : '<Please update your card to see>'}</b>
                        </div>
                        <div className='d-flex align-items-center mtz-gap-4'>
                          <IconButton size='small' className='text-danger' onClick={() => onDelCard(c.id)}>
                            <Delete />
                          </IconButton>
                          {
                            c.asDefault ?
                              <small><i className='mx-1 text-muted'><b>Currently used</b></i></small> :
                              <button className='text-nowrap btn btn-outline-primary mx-1 mtz-btn-sm' onClick={() => onCardDefault(c)}>set default</button>
                          }
                        </div>
                      </div>
                      <div>
                        <small>Created: <i><Moment fromNow>{c.createdAt && new Date(c.createdAt).toLocaleString()}</Moment></i></small>
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </div>
        </>
      }
    </div>
  );
}

function CardForm({ onCreate }) {
  let [cardErr, setCardErr] = React.useState();
  let [cardName, setCardName] = React.useState();
  const stripe = useStripe();
  const elements = useElements();

  const createCard = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setCardErr(result.error.message);
    } else {
      let card = {
        name: cardName,
        tmpToken: result.token.id
      };

      mtzApis.startSpinningIcon();
      card = await paymentService.createCard(card);
      mtzApis.stopSpinningIcon();

      if (card) {
        alert('Card created');
        setCardName('');
        setCardErr('');
        if (onCreate)
          onCreate(card);
      }
    }
  };

  const onCardChange = async (event) => {
    setCardErr(event.error ? event.error.message : "");
  };

  return <div>
    <form onSubmit={createCard} className='d-flex flex-column mtz-gap-16'>
      {
        cardErr &&
        <div className='text-danger font-weight-bold'>{cardErr}</div>
      }

      <div className='form-group'>
        <label>Card nickname:</label>
        <input className='form-control' onChange={e => setCardName(e.target.value)} />
      </div>

      <div className='form-control mtz-p-8'>
        <CardElement onChange={onCardChange} />
      </div>

      <div className='text-right'>
        <button className='btn btn-primary mtz-btn'>Submit</button>
      </div>
    </form>
  </div>
}

export default CardManager;