import React from 'react';
import AuthContext from '../AuthContext';


export default Component => (
	props => (
		<AuthContext.Consumer>
			{val => <Component {...props} me={val} />}
		</AuthContext.Consumer>
	)
)