import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createMtzPointPromotion = mtzPointPromotion => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-promotions`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, mtzPointPromotion, headers);
}

export const deleteMtzPointPromotionById = mtzPointPromotionId => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-promotions/${mtzPointPromotionId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzPointPromotionById = mtzPointPromotionId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-promotions/${mtzPointPromotionId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzPointPromotions = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-promotions?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzPointPromotionStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-promotions/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const updateMtzPointPromotionById = (mtzPointPromotionId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-point-promotions/${mtzPointPromotionId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}






