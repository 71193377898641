import id from "date-fns/locale/id";
import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createUserBioBanner = formData => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/banners`;
  let headers = {
    // "Content-Type": "application/json",
    // enctype: 'multipart/form-data',
  };
  return commonUtil.httpclient(method, url, formData, headers);
};

export const updateUserBioBannerById = (id, formData) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-bios/${id}/banners`;
  let headers = {
    // "Content-Type": "application/json",
    // enctype: 'multipart/form-data',
  };
  return commonUtil.httpclient(method, url, formData, headers);
};