import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getPostComments = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-comments?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getPostCommentStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-comments/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getPostCommentById = (postCommentId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-comments/${postCommentId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const createPostComment = (postComment) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-comments`;
    let headers = {};
    return commonUtil.httpclient(method, url, postComment, headers);
};

export const deletePostCommentById = (postCommentId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-comments/${postCommentId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};


export const updatePostCommentById = (postCommentId, postComment) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-comments/${postCommentId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, postComment, headers);
};