import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCompanyExtraQuestions = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-questions?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getCompanyExtraQuestionById = (questionId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-questions/` + questionId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, questionId, headers);
};

const deleteCompanyExtraQuestionById = (questionId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-questions/${questionId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const createCompanyExtraQuestion = (newCompanyExtraQuestion) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-questions`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newCompanyExtraQuestion, headers);
};

const updateCompanyExtraQuestionById = (questionId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-extra-questions/${questionId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export {
  getCompanyExtraQuestions,
  getCompanyExtraQuestionById,
  deleteCompanyExtraQuestionById,
  updateCompanyExtraQuestionById,
  createCompanyExtraQuestion
}