import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getFeedComments = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comments?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedCommentStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comments/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedCommentById = (feedCommentId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comments/${feedCommentId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createFeedComment = (comment) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comments`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, comment, headers);
};

export const deleteFeedCommentById = (feedCommentId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comments/${feedCommentId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateFeedCommentById = (feedCommentId, comment) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comments/${feedCommentId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, comment, headers);
};
