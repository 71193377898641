import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGEventTicketOrder = (cGEventTicketOrder) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/orders`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, cGEventTicketOrder, headers);
};

export const getCGEventTicketOrderById = (cGEventTicketOrderId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/orders/${cGEventTicketOrderId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventTicketOrders = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/orders?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventTicketOrderStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/orders/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};