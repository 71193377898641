import commonUtil from "../common";
import {
    CHAT_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const createRoomInvite = (params) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/invites`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};

const getRoomInvites = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/invites?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

const getRoomInviteStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/invites/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

const getRoomInviteById = (inviteId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/invites/${inviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

const updateRoomInviteById = (inviteId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/invites/${inviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};

const deleteRoomInviteById = (inviteId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/invites/${inviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export { createRoomInvite, deleteRoomInviteById, getRoomInviteById, getRoomInviteStats, getRoomInvites, updateRoomInviteById };

