import commonUtil from "../common";
import {
  SERVER_API_ALL,
  COMPANY_SERVER_URL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createMtzWalletTransaction = mtzWalletTransaction => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-transactions`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, mtzWalletTransaction, headers);
}

export const getMtzWalletTransactionById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-transactions/${id}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzWalletTransactions = query => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-transactions?${query.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzWalletTransactionStats = query => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet-transactions/stats?${query.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}