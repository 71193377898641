import React from "react";
import {
  LineChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer
} from "recharts";

export default function LineChartWrapper({ data, xAxis,name }) {
  return (
    <div>
      <h5 style={{textAlign:"center"}}>Line Chart</h5>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart width={400} height={250} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis} />
          <YAxis dataKey="count" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            name={name}
            dataKey="count"
            fill="#8884d8"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
