import commonUtil from "../common";
import {
  SERVER_API_ALL,
  COMPANY_SERVER_URL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createAuthReq = authReq => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/auth-reqs`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, authReq, headers);
};
