import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getProductSearchStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-searches/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, { spinnerOn: false }, headers);
};

const getProductSearches = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-searches?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export {
  getProductSearches,
  getProductSearchStats
}


