import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const deleteCompanyMemberById = companyMemberId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-members/${companyMemberId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyMemberById = companyMemberId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-members/${companyMemberId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyMembers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-members?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

export const getCompanyMemberStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-members/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

