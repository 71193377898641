import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMtzPlanPrices = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plan-prices?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMtzPlanPriceById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plan-prices/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const createMtzPlanPrice = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plan-prices`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const updateMtzPlanPriceById = (id, changes) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plans-prices/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, changes, headers);
}

const deleteMtzPlanPriceById = id => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plans-prices/${id}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export {
  getMtzPlanPrices,
  getMtzPlanPriceById,
  createMtzPlanPrice,
  deleteMtzPlanPriceById
}