import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyPrivateFile = (companyPrivateFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files`;
  let headers = {};
  return commonUtil.httpclient(method, url, companyPrivateFile, headers);
};

export const deleteCompanyPrivateFileById = (companyPrivateFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyPrivateFileById = (companyPrivateFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyPrivateFiles = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCompanyPrivateFileStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCompanyPrivateFileById = (companyPrivateFileId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};

export const viewCompanyPrivateFileById = (companyPrivateFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-private-files/${companyPrivateFileId}/view`;
  let headers = {};
  return downloadFile(url, method, headers);
};

export const downloadFile = async (url, method, headers = {}) => {
  const tokens = commonUtil.getMTZLoginTokens();
  let token = tokens && tokens.length > 0 && tokens[0];
  let vals = token ? Object.values(token) : null;
  let tokenStr = vals ? vals[0] : "";
  let userId = null;
  if (tokenStr) {
    let body = tokenStr.split(".")[1];
    userId = JSON.parse(atob(body)).userId;
  }

  const requestOptions = {
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${tokenStr}`,
      "Access-Control-Allow-Origin": "*",
    },
    mode: "cors",
  };

  let resMap = {};

  let res = await fetch(url, requestOptions);
  if (res.status === 200) {
    let disposition = res.headers.get("Content-Disposition");
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        let filename = matches[1].replace(/['"]/g, "");
        resMap["filename"] = filename;
      }
    }
    resMap["blob"] = await res.blob();

    return resMap;
  } else {
    res = await res.text();
    if (res) res = JSON.parse(res);

    switch (res.status) {
      case 401:
        window.location.href = `/logout?redirectUrl=${window.location.href}&statusCode=401`;
        break;
      case 503:
        window.location.href = `/logout?redirectUrl=${window.location.href}&statusCode=503`;
        break;
      case (400, 404):
        console.log("DEV ONLY: " + JSON.stringify(res));
        alert(res.message);
        return;
        break;
      default:
        console.log("DEV ONLY: " + JSON.stringify(res));
        alert(res.message);
        return;
        break;
    }
  }
};
