import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createFeedFile = (feedFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-files`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, feedFile, headers);
};

export const deleteFeedFileById = (feedFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-files/${feedFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedFileById = (feedFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-files/${feedFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedFiles = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-files?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getFeedFileStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-files/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateFeedFileById = (feedFileId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-files/${feedFileId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};
