import React from "react";

import Moment from "react-moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../../../AuthContext";
import { roomEventPublish } from "../../../../redux/actions/roomEventActions";
import mtzApis from "../../../../services";
import VideoCallButton from "../../calls/VideoCallButton";

const { chatService, companyService } = mtzApis;

function MessageManager({ room, newMessageEvent, newMessageFileEvent, history }) {
    let [localRoom, setLocalRoom] = React.useState(room);
    let [text, setText] = React.useState('');
    let [messages, setMessages] = React.useState([]);
    let [messageStats, setMessageStats] = React.useState({ all: { count: 0 } });
    const limit = 10;
    const me = React.useContext(AuthContext);
    const lastMsgRef = React.useRef();
    const modalUpfile = React.useRef();
    const chatDivRef = React.useRef();

    React.useEffect(() => {
        let mounted = true;
        let interval;

        (async () => {
            if (mounted) setMessages([])
            if (mounted) setText('');
            if (room) {
                localRoom = room;
                
                // load room info
                if (localRoom.meta.type === 'RFQROOM') {
                    let imgs = await companyService.getRfqImages(new URLSearchParams(`rfqIds=${localRoom.meta.rfqId}`));
                    if (imgs[0])
                        localRoom.logoPath = companyService.getBackendHost() + imgs[0].path;
                } else if (localRoom.meta.type === 'DIRECTROOM') {
                    localRoom = await chatService.getDirectRoomById(localRoom.id);
                    if (localRoom.logoPath)
                        localRoom.logoPath = companyService.getBackendHost() + localRoom.logoPath;
                } else if (localRoom.meta.type === 'REGULARROOM') {
                    localRoom = await chatService.getRoomById(localRoom.id);
                    if (localRoom.logoPath)
                        localRoom.logoPath = chatService.getBackendHost() + localRoom.logoPath;
                }

                // load messages
                messageStats = await chatService.getMessageStats(new URLSearchParams(`roomIds=${localRoom.id}`));
                if (mounted) setMessageStats(messageStats);

                await loadNewMessages(true);
                setLocalRoom(localRoom);
            }

            interval = setInterval(() => {
                if (scrollToBottom())
                    clearInterval(interval);
            }, 100);
        })();

        return () => {
            mounted = false;
            clearInterval(interval);
        };
    }, [room]);

    // message event
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        if (!localRoom || msg.roomId !== localRoom.id)
            return;

        (async () => {
            if (newMessageEvent.action === 'CREATED') {
                msg = (await loadMessageInfo([msg]))[0];
                setMessageStats(prevStats => ({ all: { count: prevStats.all.count + 1 } }));
                setMessages(prevMessages => [msg, ...prevMessages]);
                scrollToBottom();
                chatService.updateMessageById(msg.id, { _read: true });
            }

            if (newMessageEvent.action === 'UPDATED') {
                msg = (await loadMessageInfo([msg]))[0];
                setMessages(prevMessages => prevMessages.map(m => m.id === msg.id ? msg : m));
            }

            if (newMessageEvent.action === 'DELETED') {
                messages = messages.filter(m => m.id !== msg.id);
                setMessages(prevMessages => prevMessages.filter(m => m.id !== msg.id));
                setMessageStats(prevStats => ({ all: { count: messageStats.all.count - 1 } }));
            }
        })();
    }, [newMessageEvent]);

    // message file event
    React.useEffect(() => {
        (async () => {
            if (!newMessageFileEvent || !newMessageFileEvent.resource)
                return;

            let mFile = newMessageFileEvent.resource;
            if (newMessageFileEvent.action === 'CREATED') {
                messages = messages.map(m => {
                    if (m.id !== mFile.messageId)
                        return m;
                    m.mFile = mFile;
                    return m;
                });
            }
        })();
    }, [newMessageFileEvent]);

    const loadNewMessages = async forced => {
        // load new messages
        if (!forced)
            if (messages.length !== 0 && messages.length >= messageStats.all.count)
                return;


        let msgs = await chatService.getMessages(new URLSearchParams(`roomIds=${localRoom.id}&skip=${messages.length}&limit=${limit}`));
        msgs = await loadMessageInfo(msgs);
        messages = [...messages, ...msgs];
        setMessages(messages);

        // update my reads
        let unreadMsgs = messages.filter(m => !m.viewerIds.includes(me.userId));
        if (!unreadMsgs || !unreadMsgs[0])
            return;
        let reqs = unreadMsgs.map(m => chatService.updateMessageById(m.id, { _read: true }));
        let data = await Promise.allSettled(reqs);
        let updateds = data.map(res => res.value);
        updateds = await loadMessageInfo(updateds);
        let map = {};
        updateds = updateds.forEach(update => map[update.id] = update);
        messages = messages.map(m => map[m.id] || m);
        setMessages(messages);
    };

    const loadMessageInfo = async newMessages => {
        let ownerIds = newMessages.map(m => m.ownerId);
        let users = await companyService.getUsers(new URLSearchParams(`userIds=${ownerIds}`));
        let uMap = {};
        users.forEach(u => uMap[u.id] = u);
        newMessages.forEach(m => m.owner = uMap[m.ownerId]);

        let mFileIds = newMessages.filter(m => !!m.meta && !!m.meta.messageFileId ? true : false).map(m => {
            if (m.meta)
                if (m.meta.messageFileId)
                    return m.meta.messageFileId;
        });
        let mFiles = await chatService.getMessageFiles(new URLSearchParams(`messageFileIds=${mFileIds}`));
        if (mFiles) {
            let map = {};
            mFiles.forEach(f => map[f.id] = f);
            newMessages.forEach(m => m.mFile = m.meta && m.meta.messageFileId ? map[m.meta.messageFileId] : null);
        }

        return newMessages;
    };

    const createMessage = async e => {
        e.preventDefault();

        if (!text)
            return;

        let created = await chatService.createMessage({ roomId: localRoom.id, content: text });
        if (created) {
            setText('');
            scrollToBottom();
        }
    };

    const deleteMessage = async msg => {
        const ok = await window.createMtzConfirm(`Are you sure?`);
        if (!ok) return;

        await chatService.deleteMessageById(msg.id);
    };

    const editMessage = async (e, msg) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const jsonData = Object.fromEntries(formData);

        if (!jsonData.content)
            return;

        let updated = await chatService.updateMessageById(msg.id, jsonData);
        if (updated) {
            setMessages(messages.map(m => m.id === updated.id ? updated : m));
        }
    };

    const scrollToBottom = async () => {
        if (!messages)
            return false;

        if (lastMsgRef && lastMsgRef.current) {
            lastMsgRef.current.scrollIntoView({ behavior: 'smooth' });
            return true;
        }
        return false;
    };

    const upfile = async e => {
        mtzApis.startSpinningIcon();
        e.preventDefault();
        let form = new FormData(e.target);

        let message = await chatService.createMessage({ roomId: localRoom.id, content: 'Uploaded file' });
        if (message) {
            let createParams = { messageId: message.id };
            let formData = new FormData();
            formData.append('createParams', new Blob([JSON.stringify(createParams)], { type: 'application/json' }));
            formData.append('file', form.get('file'));

            let mFile = await chatService.createMessageFile(formData);
            if (mFile) {
                message = await chatService.getMessageById(message.id);
                message.mFile = mFile;
                setMessages([message, ...messages]);
                scrollToBottom();
                modalUpfile.current.click();
            }
        }
        mtzApis.stopSpinningIcon();
    };

    const handleScroll = async () => {
        if (!chatDivRef || !chatDivRef.current)
            return;

        const yPos = chatDivRef.current.scrollTop;
        if (yPos === 0) {
            await loadNewMessages();
            chatDivRef.current.scrollTop = 50;
        }
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="">
                <h5 className="mtz-h5 d-flex mtz-gap-8 align-items-center bg-light border mtz-p-8">
                    {!localRoom && 'Select a room to see messages'}

                    {
                        localRoom &&
                        <>
                            <button onClick={() => history.push(`/chat`)} className="d-lg-none text-primary btn rounded-circle">
                                <span className="fa fa-arrow-left"></span>
                            </button>

                            {
                                localRoom.logoPath &&
                                <img className="rounded-circle" style={{ height: '40px', maxWidth: '40px' }}
                                    src={localRoom.logoPath} />
                            }

                            {
                                !localRoom.logoPath && localRoom.meta.type === 'DIRECTROOM' &&
                                <span className="text-muted fa fa-user-circle" style={{ fontSize: '40px' }}></span>
                            }

                            {
                                !localRoom.logoPath && localRoom.meta.type === 'REGULARROOM' &&
                                <span className="text-muted fa fa-users" style={{ fontSize: '40px' }}></span>
                            }

                            {localRoom.name} <small>(members: {localRoom.memberIds.length}) - messages: {messageStats ? messageStats.all.count : 0} - loaded: {messages ? messages.length : 0}</small>
                        </>
                    }
                </h5>
            </div>

            {
                localRoom &&
                <>
                    <div ref={chatDivRef} onScroll={handleScroll} className="flex-fill overflow-auto d-flex flex-column mtz-gap-16 p-2">
                        {messages === null && 'Loading...'}
                        {messages !== null && !messages && 'No message found'}
                        <div className='mt-5'></div>
                        {
                            messages && messages.length === messageStats.all.count &&
                            <div className='text-center'><b className='text-primary mb-2 mtz-h5'>All messages loaded</b></div>
                        }
                        {
                            messages && [...messages].reverse().map(msg => (
                                <div key={msg.id} className={me.userId !== msg.ownerId ? 'd-flex justify-content-end' : ''}>
                                    <div style={{ maxWidth: '80%' }}>
                                        <div className="d-inline-block">
                                            <div className="d-flex flex-column mtz-gap-4 mx-0 mx-md-5">
                                                <span>
                                                    {
                                                        localRoom.meta.type !== 'RFQROOM' ?
                                                            '' :
                                                            localRoom.meta.supplierId === msg.ownerId ? '(supplier) ' : '(buyer) '}
                                                    {
                                                        me.userId === msg.ownerId ?
                                                            <b className="text-danger">me:</b> :
                                                            <b>{`${msg.owner ? msg.owner.firstname : ''} ${msg.owner ? msg.owner.lastname : ''}:`}</b>
                                                    }
                                                </span>
                                                <div key={msg.id} className={"mtz-rounded-8 mtz-p-8" + (msg.ownerId === me.userId ? ' bg-primary text-white' : ' bg-light')}>
                                                    <div className="d-flex mtz-gap-8 text-break">
                                                        <div className="flex-fill">{msg.content}</div>
                                                        {
                                                            msg.ownerId === me.userId &&
                                                            <div className="dropdown">
                                                                <button className="px-3 btn rounded-circle text-white" data-toggle='dropdown'>
                                                                    <span className="fa fa-ellipsis-v"></span>
                                                                </button>

                                                                <div className="dropdown-menu">
                                                                    <div className="dropdown-item">
                                                                        <button data-toggle='collapse' data-target={'#collapse-edit-msg-' + msg.id} className="btn text-primary">
                                                                            <span className="fa fa-edit"></span> Edit
                                                                        </button>
                                                                    </div>

                                                                    <div className="dropdown-item">
                                                                        <button onClick={() => deleteMessage(msg)} className="btn text-danger">
                                                                            <span className="fa fa-trash"></span> Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {
                                                        msg.meta && msg.meta.messageFileId &&
                                                        <div className="">
                                                            <i>
                                                                <b>
                                                                    {
                                                                        msg.mFile ? <a target='_blank' className="btn btn-warning btn-sm" href={chatService.getBackendHost() + msg.mFile.path}>
                                                                            Open File
                                                                        </a> :
                                                                            'File not found'
                                                                    }
                                                                </b>
                                                            </i>
                                                        </div>
                                                    }

                                                    <div id={'collapse-edit-msg-' + msg.id} className="collapse">
                                                        <form className="" onSubmit={e => editMessage(e, msg)}>
                                                            <div className="form-group">
                                                                <label>Edit message:</label>
                                                                <input className="form-control" name='content' />
                                                            </div>

                                                            <div className="text-right">
                                                                <button data-target={'#collapse-edit-msg-' + msg.id} data-toggle='collapse' className="btn btn-light">Done</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <small className="flex-fill"><i>{msg.viewerIds.includes(me.userId) ? '(seen)' : ''}</i></small>
                                                    <small className=""><i><Moment fromNow>{msg.createdAt}</Moment></i></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <span ref={lastMsgRef}></span>
                    </div>

                    <div className="rounded border bg-light p-2 mtz-rounded-16">
                        <form onSubmit={createMessage}>
                            <div className="d-flex mtz-gap-8">
                                <input value={text} className="form-control p-2" placeholder="Enter message"
                                    onChange={e => setText(e.target.value)} />
                                <button className={`rounded-circle btn ${!text ? 'btn-secondary' : 'btn-success'}`}>
                                    <span className="fa fa-paper-plane">Send</span>
                                </button>
                            </div>
                        </form>

                        <div className="my-1 d-flex mtz-gap-8 justify-content-center">
                            <VideoCallButton className={'btn-sm'} room={localRoom} />

                            <button data-toggle='modal' data-target='#modal-upfile' ref={modalUpfile}
                                className="btn btn-sm rounded-circle border btn-primary">
                                <span className="fa fa-paperclip"></span>
                            </button>
                            <div className='modal' id='modal-upfile'>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header d-flex">
                                            <h5 className="flex-fill">Select file to upload:</h5>
                                            <button data-dismiss='modal' className="btn btn-sm rounded-circle">
                                                <span className="fa fa-close"></span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={upfile}>
                                                <div className="form-group">
                                                    <label>Upload file:</label>
                                                    <input className="form-control" type='file' name='file' />
                                                </div>
                                                <button className="btn btn-primary">Upload</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
    );
}

const stateToProps = (state) => ({
    newNotiEvent: state.notification_topic.new_uievent,
    newRoomEvent: state.room_topic.new_uievent,
    newPresenceEvent: state.presence_topic.new_uievent,
    newMessageEvent: state.message_topic.new_uievent,
    newMessageFileEvent: state.messageFile_topic.new_uievent
});

const dispatchToProps = (dispatch) => ({
    publishRoomEvent: (e) => dispatch(roomEventPublish(e)),
});

const MessageManagerWrapper = connect(stateToProps, dispatchToProps)(withRouter(MessageManager));

export default MessageManagerWrapper;

