import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getPhoneCodeCountries = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/phone-codes/countries`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

const getPhoneCode = (countryName) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/phone-codes/countries/${countryName}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

export {
  getPhoneCode,
  getPhoneCodeCountries,
}