import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getAdCampaignDeposits = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/deposits?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignDepositStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/deposits/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignDepositById = (adCampaignDepositId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/deposits/${adCampaignDepositId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createAdCampaignDeposit = (adCampaign) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/deposits`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, adCampaign, headers);
};
