import React from "react";
import AuthContext from "../../../../AuthContext";
import LoginForm from "../LoginForm";
import RegisterForm from "../RegisterForm";

function OneClickLoginSignUp() {
    const me = React.useContext(AuthContext);
    let [showRegister, setShowRegister] = React.useState(false);
    let [createdUser, setCreatedUser] = React.useState();

    return (
        <>
            {
                !me.userId &&
                <div>
                    {
                        !showRegister && !createdUser &&
                        <>
                            <LoginForm reload={true} />

                            <hr />

                            <div className="text-center">
                                <button className="btn btn-warning" onClick={() => setShowRegister(true)}>No account? register here</button>
                            </div>
                        </>
                    }

                    {
                        showRegister && !createdUser &&
                        <>
                            <button onClick={() => setShowRegister(false)} className="btn btn-secondary">back</button>
                            <RegisterForm onCreate={newUser => {
                                setCreatedUser(newUser);
                                setShowRegister(false);
                            }} />
                        </>
                    }

                    {
                        createdUser &&
                        <div>
                            <h5 className="text-success text-center">Congratulation, welcome to MyTradeZone</h5>
                            <div>
                                Step 1: Please check your email '{createdUser.email}' and click on the confirmation link.
                            </div>
                            <div>
                                Step 2: REFRESH this page to continue your action. <a href='#' onClick={() => window.location.reload()}>Click here to refresh this page</a>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default OneClickLoginSignUp;