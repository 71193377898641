import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCGSponsorClicks = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-clicks?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorClickStatsBySponsorId = (queries, companyGroupSponsorId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-clicks/sponsors/${companyGroupSponsorId}/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorClicksBySponsorId = (queries, companyGroupSponsorId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-clicks/sponsors/${companyGroupSponsorId}?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorClickStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-clicks/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGSponsorClickById = (cgSponsorClickId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-clicks/${cgSponsorClickId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCGSponsorClick = (cgSponsorClick) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-clicks`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgSponsorClick, headers);
};
