import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AreaChartWrapper from "./components/AreaChartWrapper";
import BarChartWrapper from "./components/BarChartWrapper";
import ComposedChartWrapper from "./components/ComposedChartWrapper";
import LineChartWrapper from "./components/LineChartWrapper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Box from "@material-ui/core/Box";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function ChartManager({ getData, name }) {

  const getDefaultStartDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setMonth(date.getMonth() - 1);
    return date.getTime();
  };

  const getDefaultEndDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    date.setTime(date.getTime() - 1);
    return date.getTime();
  };

  const [selectedGroupBy, setSelectedGroupBy] = React.useState("DAY");
  const [data, setData] = React.useState(null);
  const [selectedGraph, setSelectedGraph] = React.useState("1");
  const [startDateInMillis, setStartDateInMillis] = React.useState(() => { return getDefaultStartDate() });
  const [endDateInMillis, setEndDateInMillis] = React.useState(() => { return getDefaultEndDate() });
  const [startDate, setStartDate] = React.useState(() => { return new Date(getDefaultStartDate()) });
  const [endDate, setEndDate] = React.useState(() => { return new Date(getDefaultEndDate()) });
  const [queries, setQueries] = React.useState(() => {
    const initState = {};
    initState.startDateInMillis = getDefaultStartDate();
    initState.endDateInMillis = getDefaultEndDate();
    return initState;
  });
  const [showResults, setShowResults] = React.useState(queries ? true : false);

  const prepareData = (data, grouBy) => {
    let formattedData = [];
    switch (grouBy) {
      case "DAY":
        data = data.DAY ? data.DAY : [];
        data.forEach((obj) => {
          let newObj = {
            DAY: obj._id.YEAR + "-" + obj._id.MONTH + "-" + obj._id.DAY,
            count: obj.count,
          };
          formattedData.push(newObj);
        });

        return formattedData.sort((a, b) => {
          return new Date(a.DAY) - new Date(b.DAY);
        });
      case "MONTH":
        data = data.MONTH ? data.MONTH : [];
        data.forEach((obj) => {
          let newObj = {
            MONTH: obj._id.YEAR + "-" + obj._id.MONTH,
            count: obj.count,
          };
          formattedData.push(newObj);
        });
        return formattedData.sort((a, b) => {
          return new Date(a.MONTH) - new Date(b.MONTH);
        });
      case "YEAR":
        data = data.YEAR ? data.YEAR : [];
        data.forEach((obj) => {
          let newObj = {
            YEAR: obj._id,
            count: obj.count,
          };
          formattedData.push(newObj);
        });
        return formattedData.sort((a, b) => {
          return new Date(a.YEAR) - new Date(b.YEAR);
        });
      default:
        return data;
    }
  };

  React.useEffect(() => {
    (async () => {
      let data = await getData(queries, selectedGroupBy);
      let formattedData = prepareData(data, selectedGroupBy);
      setData(formattedData);
    })();
  }, [selectedGroupBy, queries]);

  const renderChart = (chart) => {
    switch (chart) {
      case "1":
        return (
          <LineChartWrapper data={data} xAxis={selectedGroupBy} name={name} />
        );
      case "2":
        return (
          <BarChartWrapper data={data} xAxis={selectedGroupBy} name={name} />
        );
      case "3":
        return (
          <AreaChartWrapper data={data} xAxis={selectedGroupBy} name={name} />
        );
      case "4":
        return (
          <ComposedChartWrapper
            data={data}
            xAxis={selectedGroupBy}
            name={name}
          />
        );
      default:
        return "No Chart avaliable";
    }
  };

  const selectStartDateHandler = (dateString) => {
    if (dateString !== null) {
      let date = new Date(dateString);
      date.setHours(0, 0, 0, 0);
      setStartDateInMillis(date.getTime());
      setStartDate(date);
    }
  };

  const selectEndDateHandler = (dateString) => {
    if (dateString !== null) {
      let date = new Date(dateString);
      date.setDate(date.getDate() + 1);
      date.setHours(0, 0, 0, 0);
      date.setTime(date.getTime() - 1);
      setEndDateInMillis(date.getTime());
      setEndDate(date);
    }
  };

  const onFilterHandler = () => {
    const query = {};
    if (startDateInMillis) query.startDateInMillis = startDateInMillis;
    if (endDateInMillis) query.endDateInMillis = endDateInMillis;

    if (JSON.stringify(query) === "{}") {
      alert("Please apply at least one filter");
      return;
    }
    setQueries(query);
    setShowResults(true);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box p={2} borderRadius={8} className="bg-light text-dark">
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="groupBy">Choose a GroupBy: </InputLabel>
                <Select
                  name="groupBy"
                  id="groupBy"
                  onChange={(event) => setSelectedGroupBy(event.target.value)}
                  value={selectedGroupBy}
                >
                  <MenuItem value="DAY">DAY</MenuItem>
                  <MenuItem value="MONTH">MONTH</MenuItem>
                  <MenuItem value="YEAR">YEAR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="graphs">Choose a chart type: </InputLabel>
                <Select
                  name="graphs"
                  id="graphs"
                  onChange={(event) => setSelectedGraph(event.target.value)}
                  value={selectedGraph}
                >
                  <MenuItem value="1">Line Chart</MenuItem>
                  <MenuItem value="2">Bar Chart</MenuItem>
                  <MenuItem value="3">Area Chart</MenuItem>
                  {/* <MenuItem value="4">Composed Chart</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <KeyboardDatePicker
                clearable="true"
                variant="inline"
                label="Startdate"
                format="yyyy-MM-dd"
                placeholder="2022-10-27"
                value={startDate}
                onChange={selectStartDateHandler}
                maxDate={new Date()}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <KeyboardDatePicker
                clearable="true"
                variant="inline"
                label="Enddate"
                format="yyyy-MM-dd"
                placeholder="2022-10-27"
                value={endDate}
                onChange={selectEndDateHandler}
                minDate={startDate}
                maxDate={new Date()}
              />
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                fullWidth
                className="bg-primary text-white"
                onClick={onFilterHandler}
              >
                Filter
              </Button>
            </Grid>
            <Grid item md={2} xs={12}>
              {showResults &&
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  endIcon={<ClearAllIcon />}
                  disableElevation
                  onClick={() => {
                    setStartDateInMillis(getDefaultStartDate());
                    setStartDate(new Date(getDefaultStartDate()));
                    setEndDateInMillis(getDefaultEndDate());
                    setEndDate(new Date(getDefaultEndDate()));
                    setQueries({});
                  }}
                >
                  Reset Filter
                </Button>
              }
            </Grid>
          </Grid>
        </Box>
      </MuiPickersUtilsProvider>
      <br />
      {data && data.length > 0 ? (
        renderChart(selectedGraph)
      ) : (
        <div style={{ textAlign: "center" }}>
          <h5>No Data found</h5>
        </div>
      )}
    </div>
  );
}
