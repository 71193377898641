import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { roomEventPublish } from "../../../../redux/actions/roomEventActions.js";

import AuthContext from "../../../../AuthContext.js";
import mtzApis from "../../../../services";
import "./style.css";

const { chatService } = mtzApis;

function ChatButton2({ targetUserId, publishRoomEvent }) {
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    subscribeEvents();
  }, []);

  const subscribeEvents = async () => { };

  const clickLink = async () => {
    if (!me || Object.keys(me).length === 0 || !me.userId) {
      const ok = await window.createMtzConfirm("Please login to chat");
      if (!ok) return;
      else {
        window.location.href = "/login?redirectUrl=" + window.location.pathname;
        return;
      }
    }

    let dRooms = await chatService.getRooms(new URLSearchParams(`meta.type=DIRECTROOM&memberIds=${'sdfsdf'}`));
    let room = dRooms[0];
    if (!room)
      room = await chatService.createDirectRoom({ toMemberId: targetUserId });
    if (room)
      publishRoomEvent({ resource: room, action: "VIEWED" });
  };

  return (
    <button disabled={me.userId === targetUserId}
      className="company-chat btn btn-primary mtz-btn w-100" onClick={clickLink} >
      <span className="message-button-font">Message</span>
    </button>
  );
}

const dispatchToProps = (dispatch) => ({
  publishRoomEvent: (event) => dispatch(roomEventPublish(event)),
});

const ChatButtonWrapper = connect(null, dispatchToProps)(withRouter(ChatButton2));

export default ChatButtonWrapper;
