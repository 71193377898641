import commonUtil from "../common";
import {
  ALERT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = ALERT_SERVER_URL;

const getPresences = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/presences?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, {}, headers);
}

const getPresenceStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/presences/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, {}, headers);
}

export {
  getPresences,
  getPresenceStats
};