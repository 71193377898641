import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;
export const createMtzScore = mtzPointBalance => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-scores`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, mtzPointBalance, headers);
}
export const getMyScore = query => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-score`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}
export const getMtzScores = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-scores?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}