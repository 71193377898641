import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const getMessages = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMessageStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMessageById = messageId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/${messageId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const createMessage = message => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, message, headers);
}

const deleteMessageById = messageId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/${messageId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const updateMessageById = (messageId, message) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/${messageId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, message, headers);
}

export {
  createMessage,
  deleteMessageById, getMessageById, getMessageStats, getMessages, updateMessageById
};
