import commonUtil from "../common";
import {
  METRIC_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const createAllKeywordSearchStat = async log => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/all-keyword-search-stats`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, log, headers);
}

export const getAllKeywordSearchStats = async queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/all-keyword-search-stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getAllKeywordSearchStatStats = async queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/all-keyword-search-stats/stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
}