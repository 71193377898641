import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getReferralTrackingCodeRewardById = trackingCodeId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/referral-tracking-code-rewards/${trackingCodeId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getReferralTrackingCodeRewards = queries => {
  if (!!queries) {
    let ids = queries.trackingCodeIds;
    if (!!ids) {
      ids = ids.filter(id => {
        return /^[a-zA-Z0-9]+$/.test(id);
      });
      queries.trackingCodeIds = ids;
    }

    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/referral-tracking-code-rewards?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
  }
}

export const getReferralTrackingCodeRewardStats = queries => {
  if (!!queries) {
    let ids = queries.trackingCodeIds;
    if (!!ids) {
      ids = ids.filter(id => {
        return /^[a-zA-Z0-9]+$/.test(id);
      });
      queries.trackingCodeIds = ids;
    }

  }

  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/referral-tracking-code-rewards/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}