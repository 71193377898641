import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMtzPlanSubscriptions = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plan-subscriptions?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const createMtzPlanSubscription = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plan-subscriptions`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const updateMtzPlanSubscriptionById = (id, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-plan-subscriptions/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export {
  createMtzPlanSubscription, getMtzPlanSubscriptions, updateMtzPlanSubscriptionById
};

