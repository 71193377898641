import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getProductImages = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/products/images?${decodeURIComponent(queries)}`;
    let params = {};
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
};

const getProductImageById = productId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/products/images/${productId}`;
    let params = {};
    let headers = {
        "Content-Type": "application/json",
    };

    return commonUtil.httpclient(method, url, params, headers);
};

const createProductImage = productFormData => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/products/images`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, productFormData, headers);
}

const deleteProductImageById = (productId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/products/images/` + productId;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, productId, headers);
};

export {
    createProductImage,
    deleteProductImageById,
    getProductImageById,
    getProductImages
};
