import React from 'react';

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slider from '@material-ui/core/Slider';

import BackupIcon from '@material-ui/icons/Backup';

class BannerImageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageFile: this.props.imageUrl,
      previewImageFile: this.props.imageUrl,
      openEdit: false,
      previewMode: false,
      scale: 1,
      rotate: 0
    }
  }

  componentDidMount() {
    let imageFile = this.state.imageFile;
    if (imageFile && imageFile.startsWith('http')) {
      this.urlToBase64(imageFile);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageUrl !== prevProps.imageUrl) {
      let imageFile = this.props.imageUrl;
      if (imageFile && imageFile.startsWith('http'))
        imageFile = this.urlToBase64(imageFile);
      this.setState({ previewImageFile: this.props.imageUrl, imageFile });
    }
  }

  urlToBase64 = url => fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setState({ imageFile: reader.result });
      };
      reader.onerror = err => alert(err.message);
      reader.readAsDataURL(blob)
    })
    .catch(e => console.log(e));


  onClickSave = () => {
    if (this.editor) {
      this.editor.getImage().toBlob(blob => {
        const base64 = this.editor.getImage().toDataURL();
        this.setState({ imageFile: base64, openEdit: false });
        if (this.props.onUpload)
          this.props.onUpload({
            blob,
            base64,
            ext: 'png'
          });
      });
    }
  }

  setEditorRef = (editor) => (this.editor = editor)

  handleDrop = (dropped) => {
    this.setState({ imageFile: dropped[0] })
  }

  startPreviewMode = () => {
    const previewImageFile = this.editor.getImage().toDataURL();
    this.setState({ previewImageFile, previewMode: true });
  }
  stopPreviewMode = () => this.setState({ previewMode: false });

  loadImgErr = () => {
    if (typeof this.state.imageFile !== 'object')
      this.setState({ imageFile: null });
  };

  render() {
    const { openEdit, previewMode, imageFile, previewImageFile, scale, rotate } = this.state;
    let { width, height } = this.props;
    let editorW = width > window.innerWidth * .5 ? window.innerWidth * .5 : width;
    let editorH = editorW / 4;

    return (
      <React.Fragment>
        <Dialog open={openEdit === true} fullWidth maxWidth="xl">
          <DialogTitle>
            Banner editor
          </DialogTitle>

          <DialogContent>
            <div className={previewMode ? "d-none" : ""}>
              <div>Click to upload banner: <input type="file" onChange={e => this.handleDrop(e.target.files)} /></div>
              <div><b>OR, Drag and drop imageFile below:</b></div>
            </div>

            <div className={previewMode ? "d-none" : ""}>
              <Dropzone
                onDrop={this.handleDrop}
                noClick
                noKeyboard
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="text-center my-2" {...getRootProps()}>
                    <AvatarEditor width={editorW} height={editorH}
                      border={25}
                      scale={scale}
                      rotate={rotate}
                      ref={this.setEditorRef}
                      image={this.state.imageFile} />
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>

              <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-4">
                  <b>Scale:</b>
                  <Slider min={.1} max={1.9} step={0.01} className="w-md-50 w-xm-100"
                    valueLabelDisplay="auto"
                    value={scale} onChange={(event, val) => { this.setState({ scale: val }); }} />
                </div>

                <div className="col-xs-12 col-md-6 col-lg-4">
                  <b>Rotate:</b>
                  <Slider min={-180} max={180} step={1} className="w-md-50 w-xm-100"
                    valueLabelDisplay="auto"
                    value={rotate} onChange={(event, val) => { this.setState({ rotate: val }); }} />
                </div>
              </div>

              <Button disabled={!imageFile}
                color="secondary" onClick={this.startPreviewMode} variant="contained">
                Preview
              </Button>
            </div>

            <div style={{ width, height }} className={"text-center" + (previewMode ? "" : " d-none")}>
              <img className="my-2 h-100 border mw-100 mh-100" src={previewImageFile} />
            </div>
          </DialogContent>

          <DialogActions>
            <div className={previewMode ? "" : "d-none"}>
              <Button onClick={this.stopPreviewMode}>Close preview</Button>
            </div>

            <div className={previewMode ? "d-none" : ""}>
              <Button onClick={() => this.setState({ openEdit: false })}>Cancel</Button>
              <Button variant='contained' color="primary" onClick={this.onClickSave}>Save</Button>
            </div>
          </DialogActions>
        </Dialog>

        {
          this.props.editable ?
            <div className='text-right'>
              <div className="w-100 d-flex align-items-center justify-content-center border" style={{ width, height }}>
                {
                  imageFile ?
                    <img 
                      className={"" + (this.props.className ? ' ' + this.props.className : ' mw-100 mh-100 h-100')} src={imageFile} onError={this.loadImgErr} /> :
                    <Button style={{ borderRadius: '0 0' }}
                      onClick={() => this.setState({ openEdit: true })} className="border d-flex h-100 w-100 align-items-center justify-content-center">
                      Upload banner&nbsp;<BackupIcon />
                    </Button>
                }
              </div>
              <button onClick={() => this.setState({ openEdit: true })}
                className='btn text-primary'>
                Edit banner <i className='fa fa-edit'></i>
              </button>
            </div> :
            <div className="d-flex align-items-center justify-content-center" style={{ width, height }}>
              {
                imageFile ?
                  <img style={{ borderRadius: '0 0' }} className={"mh-100 mw-100 mh-100 " + this.props.className} src={imageFile} onError={this.loadImgErr} /> :
                  <img className={"h-100 mw-100 mh-100"} src={"/assets/images/empty_banner1.png"} style={{ borderRadius: '0 0' }} />
              }
            </div>
        }

      </React.Fragment>
    )
  }
}

export default BannerImageForm;