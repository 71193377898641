import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanySearchReport = (companySearchId, companySearchReport) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-searches/${companySearchId}/reports`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companySearchReport, headers);
};
