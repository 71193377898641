import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const createRtcRequest = call => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/calls/rtc-requests`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, call, headers);
}

export {
  createRtcRequest,
}