import React from "react";
import { withRouter } from "react-router-dom";
import ProductMenuDisplayManager from "../../../components/productMenus/ProductMenuDisplayManager";
import WithThreeColumnLayout from "../../../layouts/WithThreeColumnLayout";
import mtzApis from "../../../services";
import LogoBanner from "../v2/CompanyDetailPage/LogoBanner";
import "./style.css";
import CompanyDetailCard from "../../../components/company-service/companies2/CompanyDetailCard";

const { companyService } = mtzApis;

function CompanyProductMenusPage(props) {
  let [companyId, setCompanyId] = React.useState(props.match.params.companyId);
  let [profileName, setProfileName] = React.useState(props.match.params.profileName);

  React.useEffect(() => {
    (async () => {
      let companyId = props.match.params.companyId;
      setCompanyId(companyId);

      let profileName = props.match.params.profileName;
      setProfileName(profileName);
    })();
  }, [props.match.params]);

  return (
    <div className="d-flex flex-column w-100" style={{ gap: "20px" }}>
      <LogoBanner companyId={companyId} profileName={profileName} />
      <ProductMenuDisplayManager
        profileName={profileName}
        companyId={companyId}
      />
    </div>
  );
}

function Left(props) {
  let [companyId, setCompanyId] = React.useState(props.match.params.companyId);
  let [profileName, setProfileName] = React.useState(
    props.match.params.profileName
  );

  React.useEffect(() => {
    (async () => {
      let companyId = props.match.params.companyId;
      setCompanyId(companyId);

      let profileName = props.match.params.profileName;
      setProfileName(profileName);
    })();
  }, [props.match.params]);

  return (
    <div className="w-100">
      <CompanyDetailCard companyId={companyId} profileName={profileName} />
    </div>
  );
}

export default () => (
  <WithThreeColumnLayout
    left={withRouter(Left)}
    center={withRouter(CompanyProductMenusPage)}
  />
);
