import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getEmployeeRangeCodes = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/employee-ranges?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};


export {
  getEmployeeRangeCodes,
}