import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getPostLikes = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-likes?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getPostLikeStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-likes/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getPostLikeById = (postLikeId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-likes/${postLikeId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const createPostLike = (postLike) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-likes`;
    let headers = {};
    return commonUtil.httpclient(method, url, postLike, headers);
};

export const deletePostLikeById = (postLikeId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-likes/${postLikeId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};