import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getBillingAddresses = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/billing-addresses?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const createBillingAddress = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/billing-addresses`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const deleteBillingAddressById = id => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/billing-addresses/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const updateBillingAddressById = (id, changes) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/billing-addresses/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, changes, headers);
}

export {
  getBillingAddresses,
  createBillingAddress,
  deleteBillingAddressById,
  updateBillingAddressById
}