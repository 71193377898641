import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getFeedCommentLikes = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-likes?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedCommentLikeStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-likes/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getFeedCommentLikeById = (feedCommentLikeId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-likes/${feedCommentLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createFeedCommentLike = (feedCommentLike) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-likes`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, feedCommentLike, headers);
};

export const deleteFeedCommentLikeById = (feedCommentLikeId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-likes/${feedCommentLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateFeedCommentLikeById = (feedCommentLikeId, feedCommentLike) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/feed-comment-likes/${feedCommentLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, feedCommentLike, headers);
};
