import * as billingAddressApi from './billingAddressApi';
import * as billingCountryApi from './billingCountryApi';
import * as cardApi from './cardApi';
import * as mtzCustomerApi from './mtzCustomerApi';
import * as mtzPlanApi from './mtzPlanApi';
import * as mtzPlanPriceApi from './mtzPlanPriceApi';
import * as mtzPlanSubscriptionApi from './mtzPlanSubscriptionApi';
import * as mtzSellerApi from './mtzSellerApi';

import * as mtzChargeApi from "./mtzChargeApi";
import * as mtzCouponApi from './mtzCouponApi';
import * as mtzOrderCalcApi from './mtzOrderCalcApi';
import * as mtzSellerBalanceApi from "./mtzSellerBalanceApi";
import * as mtzSellerPayoutApi from "./mtzSellerPayoutApi";
import * as mtzSellerPayoutHoldApi from "./mtzSellerPayoutHoldApi";
import * as mtzSubscriptionCalcApi from './mtzSubscriptionCalcApi';
import * as mtzWalletApi from "./mtzWalletApi";
import * as mtzWalletDepositApi from "./mtzWalletDepositApi";
import * as resourceSyncApi from './resourceSyncApi';
import * as stripeAccountDashboardLinkApi from './stripeAccountDashboardLinkApi';
import * as stripeConnectAccountApi from "./stripeAccountDashboardLinkApi";
import * as stripeAccountSetupLinkApi from './stripeAccountSetupLinkApi';
import * as syncedUserApi from './syncedUserApi';

export default {
  ...billingCountryApi,
  ...mtzCustomerApi,
  ...mtzSellerApi,
  ...mtzPlanApi,
  ...mtzPlanPriceApi,
  ...mtzPlanSubscriptionApi,
  ...cardApi,
  ...mtzOrderCalcApi,
  ...mtzSubscriptionCalcApi,
  ...billingAddressApi,

  ...stripeConnectAccountApi,
  ...stripeAccountSetupLinkApi,
  ...stripeAccountDashboardLinkApi,
  ...mtzWalletApi,
  ...mtzWalletDepositApi,
  ...mtzChargeApi,
  ...mtzSellerPayoutHoldApi,
  ...mtzSellerPayoutApi,
  ...mtzSellerBalanceApi,
  ...syncedUserApi,
  ...resourceSyncApi,
  ...mtzCouponApi
}