import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getPostFiles = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/post-files?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getPostFileStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/post-files/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getPostFileById = (postFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/post-files/${postFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createPostFile = (postFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/post-files`;
  let headers = {};
  return commonUtil.httpclient(method, url, postFile, headers);
};

export const deletePostFileById = (postFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/post-files/${postFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};