import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const getMessageFiles = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/files?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMessageFileStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/files/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMessageFileById = messageId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/files/${messageId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const createMessageFile = message => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/files`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, message, headers);
}

const deleteMessageFileById = messageId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/messages/files/${messageId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  createMessageFile,
  deleteMessageFileById, getMessageFileById,
  getMessageFileStats, getMessageFiles
};
