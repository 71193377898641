import {
  getPresences,
  getPresenceStats
} from './presenceApi';

import {
  getEmailLogs,
  getEmailStatStats,
  viewSampleEmailLogById
} from './emailLogApi';

import {
  createNotification,
  deleteNotificationById,
  getNotificationById,
  getNotifications,
  getNotificationStats,
  updateNotificationById,
} from './notificationApi';

import * as emailDeliveryApi from './emailDeliveryApi';
import * as emailTagStatApi from './emailTagStatApi';
import * as emailTagStatRefreshApi from './emailTagStatRefreshApi';

export default {
  getNotifications,
  getNotificationStats,
  getNotificationById,
  createNotification,
  updateNotificationById,
  deleteNotificationById,

  getPresences,
  getPresenceStats,

  getEmailLogs,
  getEmailStatStats,
  viewSampleEmailLogById,

  ...emailDeliveryApi,
  ...emailTagStatApi,
  ...emailTagStatRefreshApi
}