import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getSyncedCompanies = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-companies?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getSyncedCompaniesStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-companies/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};