import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const createMtzSellerPayout = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payouts`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const getMtzSellerPayouts = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payouts?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMtzSellerBalanceStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payouts/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const getMtzSellerPayoutById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payouts/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  createMtzSellerPayout, getMtzSellerBalanceStats, getMtzSellerPayoutById, getMtzSellerPayouts
};

