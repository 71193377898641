import commonUtil from "../common";
import {
    ALERT_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = ALERT_SERVER_URL;

export const getEmailTagStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-tag-stats?${queries.toString()}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}