import React from 'react';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";

function Footer(props) {

  return (
    <div className="w-100 p-3 border-top bg-dark">
      <div className="text-center text-light mb-3">
        <b><u>
          MyTradeZone &copy; {(new Date()).getFullYear()}
        </u></b>
      </div>

      <div className="mx-3">
        <div className="row">
          <List className="col-12 col-md-4 col-sm-6"
            component="nav"
            subheader={
              <h6><b className='text-white'>
                General
              </b></h6>
            }
          >
            <ListItem button>
              <NavLink className='text-white' to="/about">About Us</NavLink>
            </ListItem>

            <ListItem button>
              <NavLink className='text-white' to="/pricing">Plans</NavLink>
            </ListItem>

            <ListItem button>
              <NavLink className='text-white' to="/terms">Terms</NavLink>
            </ListItem>

            <ListItem button>
              <NavLink className='text-white' to="/privacy">Privacy</NavLink>
            </ListItem>
            <ListItem button>
              <NavLink className='text-white' to="/contact">Contact Us</NavLink>
            </ListItem>
          </List>

          <List className="col-12 col-md-4 col-sm-6"
            component="nav"
            subheader={
              <h6><b className='text-white'>
                Other
              </b></h6>
            }
          >
            <ListItem button>
              <a className="text-white" href="/forms/62dffe56dd0aa361988a8786?tc=62dffebadd0aa361988a8787">Feedback</a>
            </ListItem>
            <ListItem button>
              <a className="text-white" href="/faqs">FAQs</a>
            </ListItem>
            <ListItem button>
              <a className="text-white" href="/sitemap.xml">Sitemap</a>
            </ListItem>
            <ListItem button>
              <a className="text-white" href="/directory">Site Directory</a>
            </ListItem>
          </List>

          <List className="col-12 col-md-4 col-sm-6"
            component="nav"
            subheader={
              <h6><b className='text-white'>
                More
              </b></h6>
            }
          >
            <ListItem button>
              {/* NOTE: this is id of a form-builder of the user contact@mytradezone.com. Do no replace this ID*/}
              <a className="text-white" href="/forms/6548fc6d51aa8537da6730d8">Investor Relations</a>
            </ListItem>
            <ListItem button>
              <a className="text-white" href="/mtz-news">MyTradeZone News</a>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );
}

export default Footer;
