import commonUtil from "../common";
import { METRIC_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const createTriggerSendConfirm = (params) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/triggers/send-confirms`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};
