import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createFormBuilderItem = formBuilderItem => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-items`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, formBuilderItem, headers);
}

export const getFormBuilderItemById = formBuilderItemId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-items/${formBuilderItemId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const deleteFormBuilderItemById = formBuilderItemId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-items/${formBuilderItemId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateFormBuilderItemById = (formBuilderItemId, changes) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-items/${formBuilderItemId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, changes, headers);
}

export const getFormBuilderItems = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-items?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFormBuilderItemStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builder-items/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}