import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createProductSearchReport = (productSearchId, productSearchReport) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-searches/${productSearchId}/reports`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, productSearchReport, headers);
};
