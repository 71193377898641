import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCountries = (keyword) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/geo/countries?keyword=${keyword}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

export const getCountryByName = (countryName) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/geo/countries/${countryName}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

export const getStates = (countryName, keyword) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/geo/countries/${countryName}/states?keyword=${keyword}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

export const getStateByName = (countryName, stateName) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/geo/countries/${countryName}/states/${stateName}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

export const getCities = (countryName, stateName, keyword) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/geo/countries/${countryName}/states/${stateName}/cities?keyword=${keyword}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};

export const getCityByName = (countryName, stateName, cityName) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/geo/countries/${countryName}/states/${stateName}/cities/${cityName}`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, null, headers);
};
