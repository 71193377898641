import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCGPosts = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-posts?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCGPostStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-posts/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCGPostById = (companyGroupPostId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCGPost = (companyGroupPost) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-posts`;
  let headers = {};
  return commonUtil.httpclient(method, url, companyGroupPost, headers);
};

export const deleteCGPostById = (companyGroupPostId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};


export const updateCGPostById = (companyGroupPostId, companyGroupPost) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupPost, headers);
};