import QRCode from 'qrcode';
import React from "react";

function ShareLinkButton({ size, link = window.location.href }) {
    const modalEventShareRef = React.useRef();

    return (
        <>
            <button ref={modalEventShareRef} data-toggle='modal' data-target='#modal-event-share'
                className={"btn btn-outline-primary" + (size === 'sm' ? ' btn-sm' : '')}>
                <i className="fa fa-share"></i> Share
            </button>

            <div className='modal' id='modal-event-share'>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="w-100 d-flex align-items-center">
                                <h5 className="mtz-h5 flex-fill">Sharing this event:</h5>
                                <button onClick={() => modalEventShareRef.current.click()} className="btn btn-sm rounded-circle fa fa-close"></button>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="d-flex mtz-gap-4 justify-content-center">
                                <ViaLink link={link} />

                                <ViaQR link={link} />

                                <ViaEmail link={link} />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

function ViaLink({ link }) {
    let [copied, setCopied] = React.useState(false);
    const modalEventShareLinkRef = React.useRef();

    const copy = () => {
        setCopied(true);
        navigator.clipboard.writeText(link);
    };

    return (
        <div>
            <button ref={modalEventShareLinkRef} data-toggle='modal' data-target='#modal-event-share-link'
                className={'btn rounded btn-outline-secondary'}>
                <i className="fa fa-link"></i> Copy Link
            </button>

            <div className="modal" id='modal-event-share-link'>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex mtz-gap-4 w-100 align-items-center">
                                <div className="">
                                    <button onClick={() => modalEventShareLinkRef.current.click()} className="btn btn-secondary btn-sm rounded">back</button>
                                </div>
                                <h5 className="mtz-h5 m-0">Sharing event via link:</h5>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="d-flex mtz-gap-4 align-items-center justify-content-center w-100">
                                <b className="btn btn-link" onClick={copy} >{link}</b> <button onClick={copy} className="btn">copy <i className="fa fa-copy"></i></button>
                            </div>
                            <div className="text-center">
                                {copied && <span className="text-success">Link copied to clipboard!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ViaQR({ link }) {
    const modalEventShareQRRef = React.useRef();
    const [qr, setQr] = React.useState();

    React.useEffect(() => {
        generateLink();
    }, []);

    const generateLink = () => {
        let generatedLink = link;
        let opts = {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 1,
            width: 300,
        };
        QRCode.toDataURL(
            generatedLink,
            opts,
            function (err, url) {
                if (err) throw err;
                setQr(url);
            }
        );
    };

    return (
        <div>
            <button ref={modalEventShareQRRef} data-toggle='modal' data-target='#modal-event-share-qr'
                className={'btn rounded btn-outline-secondary'}>
                <i className="fa fa-qrcode"></i> QR Code
            </button>

            <div className="modal" id='modal-event-share-qr'>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex mtz-gap-4 w-100 align-items-center">
                                <div className="">
                                    <button onClick={() => modalEventShareQRRef.current.click()} className="btn btn-secondary btn-sm rounded">back</button>
                                </div>
                                <h5 className="mtz-h5 m-0">Sharing event via QR Code:</h5>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="text-center w-100">
                                {!qr && 'Generating QR image'}

                                {
                                    qr &&
                                    <div>
                                        <div className="text-info">
                                            <b>How to use this QR Code:</b>
                                            <ol className="text-left text-dark">
                                                <li>Let other scan this QR to access this event page.</li>
                                                <li>OR, download the QR image and post it on your website or social networks...</li>
                                            </ol>
                                        </div>
                                        <div className='text-center d-flex flex-column'>
                                            <div className='text-center'>
                                                <a href={qr} download={'MTZ-qr-code.jpeg'}
                                                    className='btn btn-primary mtz-btn-sm'>Download QR</a>
                                            </div>
                                            <div className='text-center'>
                                                <img src={qr} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ViaEmail({ link }) {
    const modalEventShareEmailRef = React.useRef();

    return (
        <div>
            <button ref={modalEventShareEmailRef} data-toggle='modal' data-target='#modal-event-share-email'
                className={'btn rounded btn-outline-secondary'}>
                <i className="fa fa-envelope"></i> Email
            </button>

            <div className="modal" id='modal-event-share-email'>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex mtz-gap-4 w-100 align-items-center">
                                <div className="">
                                    <button onClick={() => modalEventShareEmailRef.current.click()} className="btn btn-secondary btn-sm rounded">back</button>
                                </div>
                                <h5 className="mtz-h5 m-0">Sharing event via email:</h5>
                            </div>
                        </div>

                        <div className="modal-body">
                            <a target='_blank' href={`mailto:?${encodeURI(`subject=MyTradeZone Group Join Invite&body=Hi there,\nCheck out my new event on MyTradeZone here:\n${link}`)}`}>
                                Click here to send email
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareLinkButton;