import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createContactGroup = contactGroup => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contact-groups`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, contactGroup, headers);
}

export const deleteContactGroupById = contactGroupId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contact-groups/${contactGroupId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactGroupById = contactGroupId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contact-groups/${contactGroupId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactGroups = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contact-groups?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactGroupStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contact-groups/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateContactGroupById = (contactGroupId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contact-groups/${contactGroupId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}