import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getFeedHashtags = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/feed-hashtags?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
  }
  
  export const getFeedHashtagsStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/feed-hashtags/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
  }