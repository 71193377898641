import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyMtzFileRequest = (companyPrivateFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-file-requests`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyPrivateFile, headers);
};

export const deleteCompanyMtzFileRequestById = (companyPrivateFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-file-requests/${companyPrivateFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyMtzFileRequestById = (companyPrivateFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-file-requests/${companyPrivateFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyMtzFileRequests = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-file-requests?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCompanyMtzFileRequestStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-file-requests/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCompanyMtzFileRequestById = (reqId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-file-requests/${reqId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
}