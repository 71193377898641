export const withHttps = (url) =>
  url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
    schemma ? match : `https://${nonSchemmaUrl}`
  );

export const validUrl = (url) => {
  const regex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return regex.test(url);
};

export const validUrlWithHyphen = (url) => {
  const regex =
    /^((https?|ftp|smtp):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(\S*)$/;
  return regex.test(url);
};

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

export const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
};

export const updateOutboundLinks = () => {
  // Get all anchor elements in the document
  const anchorElements = document.getElementsByTagName("a");

  // Check each anchor element
  for (const anchor of anchorElements) {
    // Get the href attribute of the anchor
    const href = anchor.getAttribute("href");

    // Check if the link is external (different origin)
    if (href && href.startsWith("http") && !href.startsWith(window.location.origin)) {
      // Set the 'rel' attribute to 'noopener nofollow'
      let rel = anchor.getAttribute("rel");
      if (isBlank(rel) || rel !== "noopener nofollow")
        anchor.setAttribute("rel", "noopener nofollow");
    }
  }
};
