import commonUtil from "../common";
import {
  ALERT_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = ALERT_SERVER_URL;

const getEmailLogs = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-logs?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getEmailStatStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-logs/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const viewSampleEmailLogById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-logs/${id}/view-sample`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  getEmailLogs,
  getEmailStatStats,
  viewSampleEmailLogById
};