import commonUtil from "../common";
import { METRIC_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const getCompanyAdKeywordImpressionStatUserData = async (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keyword-impression-stats/users/data?${decodeURIComponent(
    queries
  )}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyAdKeywordImpressionStatStatsUserData = async (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-ad-keyword-impression-stats/users/data/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
};
