import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyMtzFile = (companyPrivateFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-files`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyPrivateFile, headers);
};

export const deleteCompanyMtzFileById = (companyPrivateFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-files/${companyPrivateFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyMtzFileById = (companyPrivateFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-files/${companyPrivateFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyMtzFiles = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-files?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCompanyMtzFileStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-mtz-files/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};