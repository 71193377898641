import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const deactivateCompanyGroupPostById = (companyGroupPostId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/${companyGroupPostId}/deactivate`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const reactivateCompanyGroupPostById = (companyGroupPostId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/${companyGroupPostId}/reactivate`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};
