import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

export default function(style) {
	const useStyles = makeStyles(theme => ({
		style
	}));
	
	return Component => props => {
		const classes = useStyles();
		return <Component {...props} className={classes.style} />
	};
};