import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from '../../../../AuthContext';
import mtzApis from "../../../../services";

const { companyService } = mtzApis;

function CompanyGroupDetailNavBar(props) {
  const me = React.useContext(AuthContext);
  let [profileName, setProfileName] = React.useState(props.match.params.profileName);

  const location = useLocation();
  const currentTab = location.pathname;

  React.useEffect(() => {
    (async () => {
      let profileName = props.match.params.profileName;
      setProfileName(profileName);

      let companyGroup;
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`profileName=${profileName}`)
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroup === null) {
        return;
      }
    })();
  }, [props.match.params]);

  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center mtz-gap-8 flex-wrap">
        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}`}>
          <span className={`/groups/${profileName}` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Posts</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/about`}>
          <span className={`/groups/${profileName}/about` === window.location.pathname ? 'text-primary' : 'text-secondary'}>About</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/sponsors`}>
          <span className={`/groups/${profileName}/sponsors` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Sponsors</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/events`}>
          <span className={`/groups/${profileName}/events` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Events</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/members`}>
          <span className={`/groups/${profileName}/members` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Members</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/resources`}>
          <span className={`/groups/${profileName}/resources` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Files</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/categories`}>
          <span className={`/groups/${profileName}/categories` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Categories</span>
        </NavLink>

        {/* <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/rooms`}>
          <span className={`/groups/${profileName}/rooms` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Rooms</span>
        </NavLink>

        <NavLink className="btn btn-sm mtz-h5"
          to={`/groups/${profileName}/resources`}>
          <span className={`/groups/${profileName}/resources` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Files</span>
        </NavLink>
         */}
      </div>
    </div>
  );
}

export default withRouter(CompanyGroupDetailNavBar);
