import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const createCall = call => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/calls`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, call, headers);
}

const getCallById = callId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/calls/${callId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getCalls = query => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/calls?${query.toString()}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const updateCallById = (callId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/calls/${callId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, params, headers);
};

export {
  createCall, getCallById, getCalls,
  updateCallById
};
