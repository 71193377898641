import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const createDirectRoom = (room) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/direct-rooms`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, room, headers);
};

const updateDirectRoomById = (roomId, room) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/direct-rooms/${roomId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, room, headers);
};

const deleteDirectRoomById = (roomId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/direct-rooms/${roomId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getDirectRooms = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/direct-rooms?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const getDirectRoomStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/direct-rooms/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const getDirectRoomById = (roomId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/direct-rooms/${roomId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export {
  getDirectRooms,
  getDirectRoomStats,
  getDirectRoomById,
  createDirectRoom,
  updateDirectRoomById,
  deleteDirectRoomById
}