import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCategories = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getCategoryCount = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories/count?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const getCategoryById = (categoryId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories/` + categoryId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, categoryId, headers);
};

const deleteCategoryById = (categoryId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories/${categoryId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const deleteCategories = queries => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

const createCategory = (newCategory) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newCategory, headers);
};

const updateCategoryById = (categoryId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/categories/${categoryId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export {
  getCategories,
  getCategoryCount,
  getCategoryById,
  deleteCategoryById,
  deleteCategories,
  updateCategoryById,
  createCategory
}