import commonUtil from "../common";
import {
  SERVER_API_ALL,
  COMPANY_SERVER_URL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getUserExtraQuestionTypes = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-questions/types`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getUserExtraQuestions = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-questions?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getUserExtraQuestionById = (questionId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-questions/` + questionId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, questionId, headers);
};

export const deleteUserExtraQuestionById = (questionId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-questions/${questionId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const createUserExtraQuestion = (newUserExtraQuestion) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-questions`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, newUserExtraQuestion, headers);
};

export const updateUserExtraQuestionById = (questionId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/user-extra-questions/${questionId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};
