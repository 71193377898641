import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyGroupCategory = (companyGroupMember) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, companyGroupMember, headers);
};

export const deleteCompanyGroupCategoryById = (companyGroupCategoryId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories/${companyGroupCategoryId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupCategoryById = (companyGroupCategoryId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories/${companyGroupCategoryId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupCategories = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCompanyGroupCategoryStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCompanyGroupCategoryById = (companyGroupCategoryId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories/${companyGroupCategoryId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
};

export const updateCompanyGroupCategoryLogoById = (companyGroupCategoryId, params) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-categories/${companyGroupCategoryId}/logo`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}

