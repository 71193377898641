import commonUtil from "../common";
import {
    METRIC_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const createCGSponsorClick = (params) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-click-cs`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}
export const getCGSponsorClicks = async (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-click-cs?${decodeURIComponent(
        queries
    )}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGSponsorClickStats = async (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-sponsor-click-cs/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return await commonUtil.httpclient(method, url, {}, headers);
};

