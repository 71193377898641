import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGMemberInvite = (cgMemberInvite) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-invites`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, cgMemberInvite, headers);
};

export const deleteCGMemberInviteById = (cgMemberInviteId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-invites/${cgMemberInviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getCGMemberInviteById = (cgMemberInviteId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-invites/${cgMemberInviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getCGMemberInvites = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-invites?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCGMemberInviteStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-invites/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCGMemberInviteById = (cgMemberInviteId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-invites/${cgMemberInviteId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};
