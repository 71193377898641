import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getSyncedMtzPlanSubscriptions = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-mtz-plan-subscriptions?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getSyncedMtzPlanSubscriptionStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/synced-mtz-plan-subscriptions/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};