import { Box } from "@material-ui/core";
import React from "react";
import CompanyBasicInfoViewEdit from "../../../../components/company-service/companies/CompanyBasicInfoViewEdit";
import CompanyDetailNavBar from "../../../../components/company-service/companies/CompanyDetailNavBar";
import CompanyReportIconButton from "../../../../components/company-service/companies/CompanyReportIconButton";
import CompanyBannerManager from "../../../../components/company-service/companies2/CompanyBannerManager";
import CompanyLogoManager from "../../../../components/company-service/companies2/CompanyLogoManager";
import CompanyNoteButton2 from "../../../../components/company-service/companyNotes/CompanyNoteButton2";
import FollowButton from "../../../../components/network-service/follows/FollowButton";
import CompanyWatchlistAddButton from "../../../../components/shared2/CompanyWatchlistAddButton";
import mtzApis from "../../../../services";

const { companyService } = mtzApis;

function LogoBanner({ companyId, profileName }) {
  const bannerLogoRef = React.useRef();
  const avatar = React.useRef();
  let [bannerLogoWidth, setBannerLogoWidth] = React.useState();
  let [company, setCompany] = React.useState(null);

  const getBannerLogoWidth = () => {
    if (!bannerLogoRef || !bannerLogoRef.current) return;

    let w = bannerLogoRef.current.offsetWidth;
    setBannerLogoWidth(w);
    avatar.current.style.display = "inline-block";
    avatar.current.style.position = "relative";
    avatar.current.style.top = `-${w / 7}px`;
    avatar.current.style.left = `${w / 16}px`;
    bannerLogoRef.current.style.marginBottom = `-${w / 7}px`;
  };

  React.useEffect(() => {
    window.addEventListener("resize", getBannerLogoWidth);

    (async () => {
      if (profileName) {
        let companies = await companyService.getCompanies(
          new URLSearchParams(`profileNames=${profileName}`)
        );
        company = companies && companies.length > 0 ? companies[0] : undefined;
      }

      if (!company)
        if (companyId) {
          let companies = await companyService.getCompanies(
            new URLSearchParams(`companyIds=${companyId}`)
          );
          company = companies && companies.length > 0 ? companies[0] : undefined;
        }

      setCompany(company);
      getBannerLogoWidth();
    })();

    return () => {
      window.removeEventListener("resize", getBannerLogoWidth);
    };
  }, [companyId, profileName]);

  const onReportHandler = async (company, report) => {
    mtzApis.startSpinningIcon();
    if (profileName) {
      let companySearch = await companyService.getCompanySearches(new URLSearchParams(`profileNames=${profileName}`));
      let reported = await companyService.createCompanySearchReport(companySearch[0].id, report);
      mtzApis.stopSpinningIcon();

      if (reported) {
        window.location.href = "/";
      }
      else {
        alert("Server Err")
      }
    }
  };

  if (company === null)
    return 'Loading...';

  if (company !== null && !company)
    return 'Company not found';

  return (
    <div className="d-flex flex-column w-100 mtz-gap-16">
      <div className="bg-white mtz-rounded-16 w-100 mtz-gap-16 pb-2 d-flex flex-column">
        <div ref={bannerLogoRef} className="w-100">
          <div className="w-100">
            <CompanyBannerManager
              width={bannerLogoWidth}
              profileName={company.profileName}
              companyId={company.companyId}
            />
          </div>
          <div className="d-flex">
            <div ref={avatar} className="rounded-circle shadow-sm bg-light">
              <CompanyLogoManager
                width={bannerLogoWidth / 5}
                profileName={company.profileName}
                companyId={company.companyId}
              />
            </div>
          </div>
        </div>

        <Box className="d-flex mtz-gap-4 justify-content-end mx-1">
          <Box style={{ marginRight: "4px" }}>
            <CompanyReportIconButton
              style={{ height: "30px" }}
              onReport={(report) => onReportHandler(company, report)}
            />
          </Box>
          <Box>
            <CompanyWatchlistAddButton
              profileName={company.profileName}
              companyId={company.companyId}
              style={{ height: "30px" }}
            />
          </Box>
          <Box>
            <CompanyNoteButton2
              profileName={company.profileName}
              companyId={company.companyId}
              style={{ height: "30px" }}
            />
          </Box>
          <Box>
            <FollowButton
              companyId={company.companyId}
              profileName={company.profileName}
              style={{ width: "80px", height: "30px" }}
            />
          </Box>
        </Box>

        <CompanyBasicInfoViewEdit companyId={company.companyId} profileName={company.profileName} />
      </div>

      <Box>
        <CompanyDetailNavBar profileName={company.profileName} />
      </Box>
    </div>
  );
}

export default LogoBanner;
