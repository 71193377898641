import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getAds = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/ads?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getAdStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/ads/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getAdById = (adCampaignId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/ads/${adCampaignId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const createAd = (adCampaign) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/ads`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, adCampaign, headers);
};

export const updateAdById = (adCampaignId, adCampaign) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/ads/${adCampaignId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, adCampaign, headers);
};

export const deleteAdById = (adCampaignId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/ads/${adCampaignId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
}