import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getAdSearches = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-searches?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getAdSearchStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-searches/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getAdSearchById = (adCampaignId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-searches/${adCampaignId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};