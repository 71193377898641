import Box from "@material-ui/core/Box";
import React from "react";
import { NavLink } from "react-router-dom";
import AuthContext from '../../../../AuthContext';
import mtzApis from "../../../../services";

const { companyService } = mtzApis;

export default function CompanyGroupPostManageLink({ companyGroupId, profileName }) {
  let [companyGroup, setCompanyGroup] = React.useState(null);
  let [myCompanyGroupMember, setMyCompanyGroupMember] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let isMounted = true;

    (async () => {
      let companyGroup;
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`profileName=${profileName}`)
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroupId) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`companyGroupIds=${companyGroupId}`)
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroup === null) {
        return;
      }

      if (isMounted) setCompanyGroup(companyGroup);
      let company = await companyService.getMyCompany();
      if (company) {
        let companyGroupMembers = await companyService.getCompanyGroupMembers(
          new URLSearchParams(`companyGroupIds=${companyGroup.id}&userIds=${me.userId}`)
        );

        if (companyGroupMembers && companyGroupMembers.length > 0) {
          if (isMounted) setMyCompanyGroupMember(companyGroupMembers[0]);
        }
      }
    })();

    return () => { isMounted = false; };
  }, [profileName, companyGroupId]);

  return (
    <React.Fragment>
      {companyGroup && myCompanyGroupMember && myCompanyGroupMember.role === "ADMIN" && (
        <Box>
          <NavLink to={`/me/groups/${companyGroup.profileName}/posts`} className="mtz-h6">Manage posts</NavLink>
        </Box>
      )}
    </React.Fragment>
  );
}
