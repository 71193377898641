import React from 'react';
import { BottomRightDiv, LimitedDiv } from './style.js';


import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AuthContext from '../../../../AuthContext.js';
import withAuth from '../../../../hocWrappers/withAuth';
import mtzApis from '../../../../services';
import MessageManager from "../../messages/MessageManager";
import RoomManager from '../../rooms/RoomManager';

const { chatService } = mtzApis;

function InstantChatManager({ newRoomEvent, newMessageEvent }) {
    let [active, setActive] = React.useState(null);
    let [unreadStats, setUnreadStats] = React.useState();
    const collapseOpenInstantChatRef = React.useRef();
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        if (!me || !me.sessionId)
            return;
        init();
    }, []);

    const init = async () => {
        // load unreads
        let stats = await chatService.getMessageStats(new URLSearchParams(`notSeenBy=${me.userId}`));
        if (stats)
            setUnreadStats(stats);
    };

    // room events
    React.useEffect(() => {
        if (newRoomEvent.action === 'VIEWED' && newRoomEvent.resource) {
            setActive(newRoomEvent.resource);
            if (!active || (active.id !== newRoomEvent.resource.id))
                collapseOpenInstantChatRef.current.click();
        }
    }, [newRoomEvent]);

    // message events
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        if (newMessageEvent.action === 'DELETED') {
            if (!msg.viewerIds.includes(me.userId))
                return;
            setUnreadStats(prev => ({ all: { count: prev.all.count <= 0 ? 0 : prev.all.count - 1 } }));
        }

        if (newMessageEvent.action === 'CREATED') {
            if (msg.viewerIds.includes(me.userId))
                return;
            setUnreadStats(prev => ({ all: { count: prev.all.count < 100 ? prev.all.count + 1 : prev.all.count } }));
        }

        if (newMessageEvent.action === 'UPDATED') {
            (async () => {
                let stats = await chatService.getMessageStats(new URLSearchParams(`notSeenBy=${me.userId}&limit=100`));
                setUnreadStats(stats);
            })();
        }
    }, [newMessageEvent]);

    return (
        <React.Fragment>
            {
                <BottomRightDiv>
                    {
                        !me || !me.userId || !me.sessionId ?
                            "" :
                            <div className="vw-25 d-flex flex-column">
                                <div className='d-flex'>
                                    <button ref={collapseOpenInstantChatRef} data-toggle='collapse' data-target='#collapse-instant-chat'
                                        className='btn btn-primary rounded-0'>
                                        Messages <span className='fa fa-message'></span>
                                    </button>
                                    {
                                        unreadStats && unreadStats.all && unreadStats.all.count > 0 &&
                                        <span><b style={{ padding: '0px 8px', position: 'relative', top: '-23px', left: '-5px' }}
                                            className='btn btn-danger rounded-circle mtz-b3 text-white'>
                                            {unreadStats.all.count < 100 ? unreadStats.all.count : '99+'}
                                        </b></span>
                                    }

                                    <span className='flex-fill'></span>

                                    <span data-toggle='tooltip' data-title='Click to open large chat'>
                                        <NavLink to='/chat' className='ml-auto'>
                                            <button className='btn rounded-circle'>
                                                <span className='fa fa-external-link'></span>
                                            </button>
                                        </NavLink>
                                    </span>
                                </div>

                                <div id='collapse-instant-chat'
                                    className="collapse shadow mtz-card rounded">
                                    {
                                        !unreadStats ?
                                            'Chat service not available' :
                                            (
                                                active ?
                                                    <div className='mb-4'>
                                                        <LimitedDiv>
                                                            <button onClick={() => setActive()} className='btn text-primary'>
                                                                <span className='fa fa-arrow-left mx-2'></span> Back to rooms
                                                            </button>
                                                            <MessageManager room={active} />
                                                        </LimitedDiv>
                                                    </div> :
                                                    <LimitedDiv>
                                                        <RoomManager room={active} onChange={updated => setActive(updated)} />
                                                    </LimitedDiv>
                                            )
                                    }
                                </div>
                            </div>
                    }
                </BottomRightDiv>
            }
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    newMessageEvent: state.message_topic.new_uievent,
    newRoomEvent: state.room_topic.new_uievent,
});

const InstantChatManagerWrapper = connect(
    mapStateToProps
)(InstantChatManager);

export default withAuth(InstantChatManagerWrapper);