import commonUtil from "../common";
import {
    SERVER_API_ALL,
    PAYMENT_SERVER_URL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

export const createMtzWallet = mtzWallet => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallets`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, mtzWallet, headers);
}

export const getMyWallet = query => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-wallet`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}