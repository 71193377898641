import commonUtil from "../common";
import {
    METRIC_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const createCGMemberClick = (params) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-click`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}
export const getCGMemberClicks = async (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-click?${decodeURIComponent(
        queries
    )}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberClickStats = async (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-click/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return await commonUtil.httpclient(method, url, {}, headers);
};

