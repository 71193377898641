import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

export default function BarChartWrapper({ data, xAxis,name }) {
  return (
    <div style={{textAlign:"center"}}>
      <h5>Bar Chart</h5>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart width={400} height={250} data={data} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis} />
          <YAxis dataKey="count" />
          <Tooltip />
          <Legend />
          <Bar name={name} dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
