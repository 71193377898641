import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer,
} from "recharts";

export default function ComposedChartWrapper({
  data,
  xAxis,
  areaName,
  barName,
  lineName,
}) {
  return (
    <div style={{textAlign:"center"}}>
      <h5>Composed Chart</h5>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart width={400} height={250} data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis} />
          <YAxis dataKey="count" />
          <Tooltip />
          <Legend />
          {areaName && (
            <Area
              type="monotone"
              name={areaName}
              dataKey="count"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          )}
          {lineName && (
            <Line
              type="monotone"
              name={lineName}
              dataKey="count"
              fill="#8884d8"
              stroke="#ff7300"
            />
          )}
          {barName && (
            <Bar name={barName} barSize={20} dataKey="count" fill="#413ea0" />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
