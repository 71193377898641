import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getAdCampaignBudgets = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/budgets?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignBudgetStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/budgets/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getAdCampaignBudgetById = (adCampaignBudgetId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/budgets/${adCampaignBudgetId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const createAdCampaignBudget = (adCampaignBudget) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/budgets`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, adCampaignBudget, headers);
};

export const deleteAdCampaignBudgetById = (adCampaignBudgetId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/ad-campaigns/budgets/${adCampaignBudgetId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
}
