import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyGroupUploadEvent = (companyGroupUploadEvent) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events`;
  let headers = {};
  return commonUtil.httpclient(method, url, companyGroupUploadEvent, headers);
};

export const deleteCompanyGroupUploadEventById = (companyGroupUploadEventId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events/${companyGroupUploadEventId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupUploadEventById = (companyGroupUploadEventId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events/${companyGroupUploadEventId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupUploadEvents = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const getCompanyGroupUploadEventStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers, true, false);
};

export const updateCompanyGroupUploadEventById = (companyGroupUploadEventId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events/${companyGroupUploadEventId}`;
  let headers = {};
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCompanyGroupUploadTemplate = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-upload-events/template`;
  let headers = {};
  return commonUtil.httpclient(method, url, null, headers);
};
