import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import Alert from "@material-ui/lab/Alert";
import React from "react";

export default function CompanyGroupCreateAlert() {
  return (
    <Alert severity="info" className="w-100">
      <h6>Group Privacy</h6>
      <br />

      <b>
        <PublicIcon fontSize="small" className="mr-1" />
        Public:&nbsp;
      </b>
      Contents of this group will be visible to all the users of MyTradeZone.
      <br />

      <b>
        <LockIcon fontSize="small" className="mr-1" />
        Private:&nbsp;
      </b>
      Contents of this group will be visible to only the members of the group.
      <br />

      <br />
      <hr />
      <div>- Members will be visible based on their privacy settings.</div>
      <div>- Anyone can request to join the group, Admin will approve the request to join the group.</div>
      <hr />
      <h6>Post Approval</h6>- You can moderate the group posts.
      <br />
      <hr />
      <div>- Group name can only contain these characters{"(0-9,A-Z,a-z,&)"}</div>
      <hr />
      <p>- Adding Country, city, state of the group will help people find your Group.</p>
    </Alert>
  );
}
