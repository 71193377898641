const mtzPlanSubscriptionEventReducer = (state = { new_uievent: {} }, payload) => {
  switch (payload.type) {
    case "MTZ_PLAN_SUBSCRIPTION_PUBLISH":
      return { ...state, new_uievent: payload.event };
    default:
      return state;
  }
};

export default mtzPlanSubscriptionEventReducer;
