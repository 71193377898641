import React from 'react';

import AuthContext from '../../../../AuthContext';

function VideoCallButton({ room, className }) {
	const me = React.useContext(AuthContext);

	React.useEffect(() => {
	}, [me.sessionId]);

	const startCall = async () => {
		if (!me.sessionId) {
			alert("Making video call failed, reload the page and retry!")
			return;
		}

		let h = window.screen.height * .9;
		let w = window.screen.width * .9;

		if (w > h) h = w
		else w = h;

		let t = -(window.screen.height - h) / 2;
		let l = (window.screen.width - w) / 2
		window.open(`/chat/calls?roomId=${room.id}`, 'MyTradeZone Calling...', `width=${w},height=${h},top=${t},left=${l}`);
	}

	return (
		<React.Fragment>
			{
				me.sessionId && room &&
				<button className={"btn btn-primary rounded-circle " + (className || "")} onClick={startCall}>
					<span className='fa fa-camera'></span>
				</button>
			}
		</React.Fragment>
	);
}

export default VideoCallButton