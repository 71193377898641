import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import styled from "styled-components";
import withStyled from "../../../../hocWrappers/withStyled";


export const BottomRightDiv = styled.div`
	position: fixed;
	bottom: 10px;
	right: 3%;
	z-index: 1150;
`;

export const LimitedDiv = styled.div`
	height: 70vh;
	width: 30vw;

	@media(max-width: 1200px) {
		height: 75vh;
		width: 50vw;
	}

	@media(max-width: 768px) {
		height: 80vh;
		width: 85vw;
	}
`;

export const StyledAccordionSummary = withStyled({
	height: "40px",
	minHeight: "30px !important",
	right: '50px'
})(AccordionSummary);

export const AccordionDetailsStyled = withStyled({
	padding: 0
})(AccordionDetails);