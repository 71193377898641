import React from "react";
import Footer from "../ParentLayout/Footer";
import './index.css';

class WithOneColumnLayout extends React.Component {


    render() {
        return (
            <div className="d-flex flex-column mtz-gap-16 justify-content-center WithOneColumnLayout">
                <div className="mx-auto WithOneColumnLayout-content">
                    {this.props.center && <this.props.center />}
                </div>

                <div className="flex-grow-1"></div>

                <Footer />
            </div>
        );
    };
}

export default WithOneColumnLayout;