import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getRfis = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfis?${decodeURIComponent(queries)}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
};

const getRfiById = rfiId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfis/${rfiId}`;
    let headers = {
        "Content-Type": "application/json",
    };

    return commonUtil.httpclient(method, url, null, headers);
};

const getRfiStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfis/stats?${decodeURIComponent(queries)}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
};

const createRfi = rfiFormData => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfis`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, rfiFormData, headers);
}

const updateRfiById = (id, rfi) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/rfis/` + id;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, rfi, headers);
};

export {
    createRfi, getRfiById,
    getRfiStats, getRfis,
    updateRfiById
};
