import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getNoteFiles = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/note-files?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const getNoteFileStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/note-files/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const getNoteFileById = (noteFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/note-files/${noteFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const createNoteFile = (noteFile) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/note-files`;
  let headers = {};
  return commonUtil.httpclient(method, url, noteFile, headers);
};

const deleteNoteFileById = (noteFileId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/note-files/${noteFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const updateNoteFileById = (noteFileId, noteFile) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/note-files/${noteFileId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, noteFile, headers);
};

export {
  getNoteFiles,
  getNoteFileStats,
  getNoteFileById,
  createNoteFile,
  deleteNoteFileById,
  updateNoteFileById
}