import commonUtil from "../common";
import {
  CHAT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = CHAT_SERVER_URL;

const createRoom = createParams => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, createParams, headers);
};
const getRooms = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const getRoomStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const getRoomById = (roomId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/${roomId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

const deleteRoomById = (id) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/${id}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};


const updateRoomById = (id, updateParams) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/${id}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, updateParams, headers);
};

const updateRoomLogoById = (id, updateParams) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/rooms/${id}/logo`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, updateParams, headers);
};


export {
  createRoom, deleteRoomById, getRoomById, getRoomStats, getRooms, updateRoomById, updateRoomLogoById
};

