import React from "react";
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";

const { alertService } = mtzApis;

function NotificationButton({ color, newNotiEvent, noTitle, vertical }) {
  let [notificationStats, setNotificationStats] = React.useState(null);
  let me = React.useContext(AuthContext) || {};
  const limit = 16;

  React.useMemo(() => {
    if (notificationStats && notificationStats.all) {
      ++notificationStats.all.count;
      setNotificationStats(notificationStats);
    }
  }, [newNotiEvent]);

  React.useEffect(() => {
    loadNotificationStats();
  }, [])

  const loadNotificationStats = async () => {
    let today = new Date();
    today.setDate(today.getDate() - 2);
    let startDateInMillis = today.getTime();
    notificationStats = await alertService.getNotificationStats(new URLSearchParams(`&startDateInMillis=${startDateInMillis}&hasRead=false&receiverIds=${me.userId}`));
    if (notificationStats && notificationStats.all) {
      setNotificationStats({ ...notificationStats });
    }
  }

  return (
    <React.Fragment>
      {
        me && Object.keys(me).length !== 0 &&
        <span>
          <NavLink to='/me/notifications' className='btn btn-sm'>
            <div className={`align-items-center d-flex ${vertical === false ? '' : ' flex-column'}`} >
              {
                notificationStats && notificationStats.all && notificationStats.all.count > 0 ?
                  <span className="text-danger d-flex align-items-center">
                    <i className='fa fa-bell'></i>&nbsp;
                    {
                      notificationStats && notificationStats.all ?
                        <small>{notificationStats.all.count <= 99 ? `(${notificationStats.all.count})` : "(99+)"}</small> :
                        ''
                    }
                  </span> :
                  <i className='fa fa-bell'></i>
              }
              {!noTitle && <small className="text-nowrap">
                Alerts
              </small>}
            </div>
          </NavLink>
        </span >
      }
    </React.Fragment>
  )
}

NotificationButton.defaultProps = {
  color: 'black',
}

const stateToProp = state => ({ newNotiEvent: state.notification_topic.new_uievent });

const NotificationButtonWrapper = connect(stateToProp)(NotificationButton);

export default NotificationButtonWrapper;
