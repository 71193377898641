import React from 'react';

import { connect } from 'react-redux';


import AuthContext from '../../../../AuthContext';
import { callEventPublish } from '../../../../redux/actions/callEventActions';
import mtzApis from '../../../../services';
const { chatService } = mtzApis;

function VideoCallPopup({ newCallEvent }) {
	const me = React.useContext(AuthContext);

	React.useMemo(() => {
		(async () => {
			if (newCallEvent.action === 'CREATED') {
				let call = newCallEvent.resource;

				if (call.callMembers.find(m => m.userId === me.userId))
					return;

				let room = await chatService.getRoomById(call.roomId);
				const ok = await window.createMtzConfirm(`You have a call from ${room.name}`);
				if (!ok) return;

				let h = window.screen.height * .9;
				let w = window.screen.width * .9;

				if (w > h) h = w
				else w = h;

				let t = -(window.screen.height - h) / 2;
				let l = (window.screen.width - w) / 2
				window.open(`/chat/calls?roomId=${room.id}`, 'MyTradeZone Calling...', `width=${w},height=${h},top=${t},left=${l}`);
			}
		})();
	}, [newCallEvent]);

	return (
		<React.Fragment>
		</React.Fragment>
	);
}

const stateToProp = state => ({
	newCallEvent: state.call_topic.new_uievent,
});

const dispatchToProps = dispatch => ({
	publishCallEvent: e => dispatch(callEventPublish(e))
});

export default connect(stateToProp, dispatchToProps)(VideoCallPopup);