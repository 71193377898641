import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import { FLAG_BLUE_ICON } from "../../../../utils/constants/icons";
import AuthContext from "../../../../AuthContext";

export default function CompanyReportIconButton({ onReport, style }) {
  let [open, setOpen] = React.useState(false);
  let [report, setReport] = React.useState({ reason: "SPAM_OR_MISLEADING" });

  const me = React.useContext(AuthContext) || {};

  const onReportHandler = async (e) => {
    e.preventDefault();

    if (!me || !me.userId) {
      let login = await window.createMtzConfirm("You need to login");
      if (login) {
        let currentPathName = window.location.pathname;
        window.location.href = "/login?redirectUrl=" + currentPathName;
      }
    }
    else {
      let res = await window.createMtzConfirm(`Are you sure you want to report the company?`);
      if (!res) return;
      onReport(report);
      setReport({ reason: "SPAM_OR_MISLEADING" });
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Report the content">
        <button
          className="text-center mtz-rounded-8 btn btn-outline-primary btn-sm"
          onClick={() => setOpen(true)}
          style={style}
        >
          <img src={FLAG_BLUE_ICON} width="17px" height="17px" />
        </button>
      </Tooltip>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Report the company</DialogTitle>
        <DialogContent dividers>
          <form onSubmit={(e) => onReportHandler(e)}>
            <div className="d-flex flex-column">
              <div className="form-group">
                <label className="form-group-label">Select Reason</label>
                <select
                  value={report.reason || ""}
                  className="form-control"
                  required
                  onChange={(e) => setReport({ ...report, reason: e.target.value })}
                >
                  <option value="">---select---</option>
                  <option value="SPAM_OR_MISLEADING">Spam or misleading</option>
                  <option value="COPYRIGHT_INFRINGEMENT">Copyright infringement</option>
                  <option value="INAPPROPRIATE_CONTENT">Inappropriate content</option>
                  <option value="OTHER_SITE_VIOLATION">Other</option>
                </select>
              </div>
            </div>
            {report.reason === "OTHER_SITE_VIOLATION" && (
              <div className="d-flex flex-column">
                <div class="form-group">
                  <label>Comment</label>
                  <textarea
                    class="form-control"
                    rows="4"
                    onChange={(e) => setReport({ ...report, description: e.target.value })}
                  ></textarea>
                </div>
              </div>
            )}

            <button type="submit" className="btn btn-primary btn-block btn-sm text-white">
              Submit
            </button>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-secondary text-white" onClick={() => setOpen(false)}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
