import React from 'react';

import googleOneTap from 'google-one-tap';
import { NavLink, withRouter } from 'react-router-dom';
import HomeSearchBoxNew from '../../components/shared2/HomeSearchBoxNew';
import mtzApis from "../../services";
import { GOOGLE_CLIENT_ID } from '../../services/config';
import './NonLoginHeader.css';
const { companyService, commonUtil } = mtzApis;

function NonLoginHeader({ asSimple }) {
    const renderMenuItemsXl = () => (
        <div className='w-100 d-flex mtz-gap-16 align-items-center'>
            {
                !asSimple ?
                    <div className='d-flex mtz-gap-16'>
                        {
                            (window.location.pathname.startsWith('/search')) ?
                                <div className='d-none d-xl-inline-block flex-fill'>
                                    <HomeSearchBoxNew />
                                </div> :
                                <>
                                    <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/search'>Search</NavLink>
                                    <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/about'>About Us</NavLink>
                                    <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/pricing'>Pricing</NavLink>
                                    <NavLink className='mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/contact'>Contact Us</NavLink>
                                </>
                        }
                    </div> :
                    <div className='w-100 flex-fill'></div>
            }

            <div className='d-flex mtz-gap-4 justify-content-center align-items-center'>
                <NavLink className='btn btn-primary mx-2 btn font-weight-bold mtz-h6 text-nowrap' to='/login'>Sign In</NavLink>
                <NavLink className='mx-2 btn btn-outline-primary font-weight-bold mtz-h6 text-nowrap' to='/register'>Join Free</NavLink>
            </div>
        </div>
    );

    const renderMenuItemsXs = () => (
        <>
            <div className='d-flex mtz-gap-4'>
                <div className='flex-fill'>
                    {
                        (window.location.pathname.startsWith('/search')) && !asSimple ?
                            <HomeSearchBoxNew /> :
                            ''
                    }
                </div>

                <button data-toggle='collapse' data-target='#collapse-top-nav' className='btn btn-sm'>
                    <span className='fa fa-bars'></span>
                </button>
            </div>
        </>
    );

    return (
        <div className='bg-white border justify-content-center d-flex flex-column shadow-sm py-1'>
            <div className="w-100 d-flex mtz-gap-4 justify-content-center align-items-center">
                <div className='mx-2'>
                    <NavLink to="/">
                        <img src='/assets/images/Logos.png' height='40' />
                    </NavLink>
                </div>

                <div style={{ width: '850px' }} className='d-flex flex-column d-xl-none'>
                    {renderMenuItemsXs()}
                </div>

                <div style={{ width: '850px' }} className='d-xl-flex d-none align-items-center'>
                    {renderMenuItemsXl()}
                </div>
            </div>

            <div className='collapse ml-4 mt-4 mb-2' id='collapse-top-nav'>
                <div className='d-flex flex-column mtz-gap-8 algin-items-center'>
                    <NavLink to='/search'>Search</NavLink>
                    <NavLink to='/about'>About Us</NavLink>
                    <NavLink to='/about'>Pricing</NavLink>
                    <NavLink to='/contact'>Contact Us</NavLink>

                    <div>
                        <NavLink className='btn btn-outline-primary btn-sm' to='/login'>Sign In</NavLink>
                        &nbsp;
                        <NavLink className='btn btn-primary btn-sm' to='/register'>Join Free</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

const options = {
    client_id: GOOGLE_CLIENT_ID, // required
    auto_select: false, // optional
    cancel_on_tap_outside: false, // optional
    context: 'signin', // optional
};
googleOneTap(options, (response) => {
    // Send response to server
    console.log(response);
});

export default withRouter(NonLoginHeader);