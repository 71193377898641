import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createProductWatchlist = productWatchlist => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, productWatchlist, headers);
}

export const deleteProductWatchlistById = productWatchlistId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists/${productWatchlistId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getProductWatchlistById = productWatchlistId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists/${productWatchlistId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getProductWatchlists = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const getProductWatchlistStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, {}, headers);
}

export const updateProductWatchlistById = (productWatchlistId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists/${productWatchlistId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export const shareProductWatchlist = (params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-watchlists/copy?${decodeURIComponent(params)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, {}, headers);
}
