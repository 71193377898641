import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getPostCaches = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-caches?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getPostCacheStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-caches/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getPostCacheById = (id) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/post-caches/${id}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};