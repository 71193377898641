import { CHAT_SERVER_URL } from "../config";

import * as callApis from './callApi';
import * as directRoomApi from './directRoomApi';
import * as messageApi from './messageApi';
import * as messageFileApi from './messageFileApi';
import * as roomApi from './roomApi';
import * as roomInviteApi from './roomInviteApi';
import * as rtcRequestApi from './rtcRequestApi';
import * as syncedCompanyApi from './syncedCompanyApi';
import * as syncedUserApi from './syncedUserApi';

const getBackendHost = () => CHAT_SERVER_URL;

export default {
  ...roomApi,
  ...roomInviteApi,
  ...messageApi,
  ...messageFileApi,
  ...callApis,
  ...rtcRequestApi,
  ...syncedCompanyApi,
  ...syncedUserApi,
  ...directRoomApi,
  getBackendHost,
};