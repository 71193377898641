import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getHealthCheckLogs = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-check-logs?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getHealthCheckLogStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/health-check-logs/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  getHealthCheckLogs,
  getHealthCheckLogStats,
}