import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCoProfilePercentStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/co-profile-percents/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

const getCoProfilePercents = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/co-profile-percents?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

export {
  getCoProfilePercentStats,
  getCoProfilePercents
};
