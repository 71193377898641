import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Divider from "@material-ui/core/Divider";
import ChartManager from "../ChartManager";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const propTypes = {
  title: PropTypes.string.isRequired,
  getData: PropTypes.func.isRequired,
};

export default function ResourceChartLink({ title, getData }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <NavLink to="#" onClick={() => setOpen(true)}>
        View Chart
      </NavLink>
      <Dialog open={open} onClose={() => setOpen(false)} PaperComponent={Paper} maxWidth="md">
        <DialogTitle disableTypography>
          <h5 className="mtz-h5 text-center">
            <AssessmentIcon />
            &nbsp;
            {title}
          </h5>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ChartManager getData={getData} name={title} />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="contained" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ResourceChartLink.propTypes = propTypes;
