import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createMtzFile = mtzFile => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, mtzFile, headers);
}

export const deleteMtzFileById = mtzFileId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/${mtzFileId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFileById = mtzFileId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/${mtzFileId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFiles = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzFileStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateMtzFileById = (mtzFileId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/${mtzFileId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export const getMtzFileStreamById = (mtzFileId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/${mtzFileId}/stream`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}





