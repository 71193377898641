// service consts
const NETWORK_SERVICE = 'NETWORK_SERVICE';
const COMPANY_SERVICE = 'COMPANY_SERVICE';


// feature consts
const TRACKING_CODES = 'TRACKING_CODES';

const get = (serviceName, featureName, key) => {
    let serviceSpace = sessionStorage.getItem(serviceName);
    if (!serviceSpace) return null;
    serviceSpace = JSON.parse(serviceSpace);
    let featureNameSpace = serviceSpace[featureName];
    if (!featureNameSpace) return null;
    return featureNameSpace[key];
};

const set = (serviceName, featureName, key, val) => {
    if (!sessionStorage.getItem(serviceName))
        sessionStorage.setItem(serviceName, '{}');
    let serviceSpace = sessionStorage.getItem(serviceName);
    serviceSpace = JSON.parse(serviceSpace);

    if (!serviceSpace[featureName])
        serviceSpace[featureName] = {};
    let featureNameSpace = serviceSpace[featureName];
    featureNameSpace[key] = val;

    sessionStorage.setItem(serviceName, JSON.stringify(serviceSpace));
};

const remove = (serviceName, featureName, key) => {
    let serviceSpace = sessionStorage.getItem(serviceName);
    if (!serviceSpace) return;
    serviceSpace = JSON.parse(serviceSpace);

    let featureNameSpace = serviceSpace[featureName];
    if (!featureNameSpace) return;

    delete featureNameSpace[key];
};

export default {
    NETWORK_SERVICE,
    COMPANY_SERVICE,
    TRACKING_CODES,
    get, set, remove
};