import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const deactivateProductById = (productId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/${productId}/deactivate`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const reactivateProductById = (productId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/${productId}/reactivate`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};
