import { GoogleApiWrapper } from 'google-maps-react';
import React from 'react';
import Geocode from "react-geocode";
import PlacesAutocomplete from 'react-places-autocomplete';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import mtzApis from '../../../services';
import { GOOGLE_API_KEY } from '../../../services/config';

const { companyService } = mtzApis;

function HomeSearchBoxNew({ placeholder }) {
  let params = new URLSearchParams(window.location.search);
  let initialKeyword = params.get("keyword") ? decodeURIComponent(params.get("keyword")) : '';
  let [suggestions, setSuggestions] = React.useState({});
  let [k, setK] = React.useState(initialKeyword);
  let [focused, setFocused] = React.useState(false);
  let [searchFocused, setSearchFocus] = React.useState(false);
  let [gAddr, setGAddr] = React.useState({ country: params.country, city: params.city, zipCode: params.zipCode });
  const searchArea = React.useRef();

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => autoComplete(k), 100);

    const handleClickOutside = document.addEventListener("click", e => {
      if (searchArea && searchArea.current && searchArea.current.contains(e.target))
        setFocused(true);
      else
        setFocused(false);
    });

    return () => {
      clearTimeout(searchTimeout);
      document.removeEventListener("focus", handleClickOutside);
    };
  }, [k, window.location.search, gAddr]);

  const onSearch = () => {
    if (!k || k.trim() === '') {
      return;
    }
    k = k.trim()

    let query = new URLSearchParams();
    query.set('keyword', k);
    query.set('type', params.get("type") || 'COMPANY');

    if (gAddr) {
      if (gAddr.country) query.set('country', gAddr.country);
      if (gAddr.city) query.set('city', gAddr.city);
      if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
    } else {
      query.delete('country');
      query.delete('city');
      query.delete('zipCode');
    }

    window.location.href = (`/search?${query}`);
  };

  const autoComplete = async key => {
    if (key && key.trim() != '') {
      let query1 = new URLSearchParams();
      query1.set('keyword', key);
      query1.set('skip', 0);
      query1.set('limit', 4);
      if (gAddr) {
        if (gAddr.country) query1.set('country', gAddr.country);
        if (gAddr.city) query1.set('city', gAddr.city);
        if (gAddr.zipCode) query1.set('zipCode', gAddr.zipCode);
      } else {
        query1.delete('country');
        query1.delete('zipCode');
        query1.delete('city');
      }

      let query2 = new URLSearchParams(Object.fromEntries(query1));
      query2.delete('skip');
      query2.delete('limit');

      let ajaxes = [
        companyService.getCompanySearches(query1),
        companyService.getCompanySearchStats(query2),
        companyService.getProductSearches(query1),
        companyService.getProductSearchStats(query2),
        companyService.getCGEventSearches(query1),
        companyService.getCGEventSearchStats(query2),
        companyService.getRfqSearches((() => {
          let q = new URLSearchParams(query1);
          if (q.get('country')) {
            q.set("ownerCountry", q.get('country'));
            q.delete('country');
          }
          if (q.get('city')) {
            q.set("ownerCity", q.get('city'));
            q.delete('city');
          }
          if (q.get('zipCode')) {
            q.set("ownerZipCode", q.get('zipCode'));
            q.delete('zipCode');
          }
          return q;
        })()),
        companyService.getRfqSearchStats((() => {
          let q = new URLSearchParams(query2);
          if (q.get('country')) {
            q.set("ownerCountry", q.get('country'));
            q.delete('country');
          }
          if (q.get('city')) {
            q.set("ownerCity", q.get('city'));
            q.delete('city');
          }
          if (q.get('zipCode')) {
            q.set("ownerZipCode", q.get('zipCode'));
            q.delete('zipCode');
          }
          return q;
        })()),
        companyService.getCompanyGroupSearches(query1),
        companyService.getCompanyGroupSearchStats(query1)
      ];

      let data = await Promise.allSettled(ajaxes);
      data = data.map(d => d.value);
      let [companySearches, companySearchStats, productSearches, productSearchStats, eventSearches, eventSearchStats, rfqSearches, rfqSearchStats, companyGroupSearches, companyGroupSearchStats] = data;

      if (productSearches) {
        let companyIds = productSearches.map(s => s.product.companyId);
        let companies = await companyService.getCompanies(new URLSearchParams(`companyIds=${companyIds}`));
        if (companies) {
          let compMap = {};
          companies.forEach(c => compMap[c.id] = c);
          productSearches.forEach(s => s.company = compMap[s.product.companyId] || {});
        }
      }

      suggestions = { ...suggestions, companySearches, companySearchStats, productSearchStats, productSearches, eventSearches, eventSearchStats, rfqSearches, rfqSearchStats, companyGroupSearches, companyGroupSearchStats };
      setSuggestions(suggestions);
    } else {
      setTimeout(() => setSuggestions({}), 200);
    }
  };

  return (
    <div className={'bg-white w-100 d-flex align-items-center'}>
      <div className={'d-flex w-100 mtz-gap-4 align-items-center'}>
        <div className='d-none d-xl-inline-block'>
          <AddrSearchGoogleWrapper onChange={addr => setGAddr(addr)} />
        </div>

        <div className='d-none d-xl-inline-block'>
          <span className='text-muted'>|</span>
        </div>

        <div ref={searchArea} className='p-1 flex-fill dropdown'>
          <div className='input-group'>
            <input onFocus={() => {
              setFocused(true);
              setSearchFocus(true);
            }} onBlur={() => setTimeout(() => setSearchFocus(false), 100)}
              onKeyDown={e => {
                if (e.key === 'Enter')
                  onSearch();
              }}
              className='form-control'
              placeholder={placeholder || "Type to search"}
              onChange={e => setK(e.target.value)}
              value={k || ''}
            />
            <div onClick={onSearch} className='input-group-append'>
              <button className='fa fa-search btn btn-primary rounded'></button>
            </div>
          </div>

          <div className={'d-xl-none collapse mt-2' + (focused ? ' show' : '')}>
            <AddrSearchGoogleWrapper onChange={addr => setGAddr(addr)} />
          </div>

          <div className={'w-100 dropdown-menu text-left vh-75 overflow-auto shadow my-2 mtz-rounded-16 bg-white' + (k && focused && searchFocused ? ' show' : '')}>
            <div className='text-right m-2'>
              <button onClick={() => setFocused(false)} className='btn btn-sm btn-secondary rounded'>
                <span className='d-flex align-items-center mtz-gap-4'>
                  close <span className='fa fa-close'></span>
                </span>
              </button>
            </div>

            {
              Object.keys(suggestions).includes('companySearches') &&
              suggestions.companySearchStats && suggestions.companySearchStats.all && suggestions.companySearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div className="d-flex align-items-center">
                  <div className='flex-fill'>
                    <h6>
                      <b>Companies found: {suggestions.companySearchStats && suggestions.companySearchStats.all ? suggestions.companySearchStats.all.count : 0}</b>
                    </h6>
                  </div>
                  <div>
                    <small>
                      <NavLink to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'COMPANY');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.companySearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.company.id}>
                        <NavLink onClick={() => setK('')}
                          className='w-100 btn btn-sm text-dark text-left' to={`/profile/${s.company.profileName}`}>
                          - {(() => {
                            let arr = s.company.name.split(' ');
                            arr = arr.map((item, idx) => item.toLowerCase() === k.toLowerCase() ? <b key={item}>{item + (idx < arr.length ? ' ' : '')}</b> : item + (idx < arr.length ? ' ' : ''));
                            return arr;
                          })().map(i => i)}
                        </NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              Object.keys(suggestions).includes('productSearches') &&
              suggestions.productSearchStats && suggestions.productSearchStats.all && suggestions.productSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div className="d-flex align-items-center">
                  <div className='flex-fill'>
                    <h6><b>Products found: {suggestions.productSearchStats && suggestions.productSearchStats.all ? suggestions.productSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'PRODUCT');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.productSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.product.id}>
                        <NavLink className='w-100 btn btn-sm text-dark text-left'
                          to={`/products/${s.company.profileName}/${s.product.sku}`}>
                          - {(() => {
                            let arr = s.product.name.split(' ');
                            arr = arr.map((item, idx) => item.toLowerCase() === k.toLowerCase() ? <b key={idx}>{item + (idx < arr.length ? ' ' : '')}</b> : item + (idx < arr.length ? ' ' : ''));
                            return arr;
                          })().map(i => i)}
                        </NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              Object.keys(suggestions).includes('eventSearches') &&
              suggestions.eventSearchStats && suggestions.eventSearchStats.all && suggestions.eventSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div className="d-flex align-items-center">
                  <div className='flex-fill'>
                    <h6><b>Events found: {suggestions.eventSearchStats && suggestions.eventSearchStats.all ? suggestions.eventSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'PRODUCT');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.eventSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.cgEvent.id}>
                        <NavLink className='w-100 btn btn-sm text-dark text-left'
                          to={`/events/${s.cgEvent.profileName}`}>
                          - {(() => {
                            let arr = s.cgEvent.name.split(' ');
                            arr = arr.map((item, idx) => item.toLowerCase() === k.toLowerCase() ? <b key={item}>{item + (idx < arr.length ? ' ' : '')}</b> : item + (idx < arr.length ? ' ' : ''));
                            return arr;
                          })().map(i => i)}
                        </NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              Object.keys(suggestions).includes('rfqSearches') &&
              suggestions.rfqSearchStats && suggestions.rfqSearchStats.all && suggestions.rfqSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div className="d-flex align-items-center">
                  <div className='flex-fill'>
                    <h6><b>RFQs found: {suggestions.rfqSearchStats && suggestions.rfqSearchStats.all ? suggestions.rfqSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'RFQ');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.rfqSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.rfq.id}>
                        <NavLink className='w-100 btn btn-sm text-dark text-left'
                          to={`/rfqs/${s.rfq.id}`}>
                          - {(() => {
                            let arr = s.rfq.title.split(' ');
                            arr = arr.map((item, idx) => item.toLowerCase() === k.toLowerCase() ? <b key={item}>{item + (idx < arr.length ? ' ' : '')}</b> : item + (idx < arr.length ? ' ' : ''));
                            return arr;
                          })().map(i => i)}
                        </NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              suggestions.companyGroupSearches &&
              suggestions.companyGroupSearchStats && suggestions.companyGroupSearchStats.all && suggestions.companyGroupSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div className="d-flex align-items-center">
                  <div className='flex-fill'>
                    <h6><b>Company groups found: {suggestions.companyGroupSearchStats && suggestions.companyGroupSearchStats.all ? suggestions.companyGroupSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'GROUP');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.companyGroupSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.companyGroup.id}>
                        <NavLink className='w-100 btn btn-sm text-dark text-left'
                          to={`/groups/${s.companyGroup.profileName}`}>
                          - {(() => {
                            let arr = s.companyGroup.name.split(' ');
                            arr = arr.map((item, idx) => item.toLowerCase() === k.toLowerCase() ? <b key={item}>{item + (idx < arr.length ? ' ' : '')}</b> : item + (idx < arr.length ? ' ' : ''));
                            return arr;
                          })().map(i => i)}
                        </NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

function AddrSearch({ onChange }) {
  let [k, setK] = React.useState('');

  React.useEffect(() => {
    let params = Object.fromEntries(new URLSearchParams(window.location.search));
    k = `${params.city ? params.city + ' ' : '' || ''}${params.zipCode ? params.zipCode + ' ' : ''}${params.country ? params.country + ' ' : ''}`;
    setK(k);
  }, [window.location.search]);

  const getGoogleAddress = async address => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage("en");

    try {
      let response = await Geocode.fromAddress(address);
      let googleAddress = {
        country: '',
        zipCode: '',
        state: '',
        city: '',
        address: '',
      };
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        let componentName = response.results[0].address_components[i].types[0];
        switch (componentName) {
          case 'country':
            googleAddress.country = response.results[0].address_components[i].short_name;
            break;
          case 'postal_code':
            googleAddress.zipCode = response.results[0].address_components[i].short_name;
            break;
          case "administrative_area_level_1":
            googleAddress.state = response.results[0].address_components[i].short_name;
            break;
          case 'locality':
            googleAddress.city = response.results[0].address_components[i].short_name;
            break;
          case 'route':
            googleAddress.streetName = response.results[0].address_components[i].short_name;
            break;
          case 'street_number':
            googleAddress.streetNumber = response.results[0].address_components[i].short_name;
            break;
        }
      }

      if (googleAddress.city === googleAddress.state)
        googleAddress.state = null

      const { lat, lng } = response.results[0].geometry.location;
      googleAddress.lat = lat;
      googleAddress.lng = lng;

      googleAddress.address = (googleAddress.streetNumber || '') + " " + (googleAddress.streetName || '');

      return googleAddress;
    } catch (e) {
      // console.log(e);
    }
  };

  const search = async val => {
    setK(val);
    let result = await getGoogleAddress(val);
    if (onChange)
      onChange(result);
    return result;
  };

  const handleSelect = async addr => {
    let result = await search(addr);
    if (!result)
      return;

    k = `${result.city ? result.city + ' ' : '' || ''}${result.zipCode ? result.zipCode + ' ' : ''}${result.country ? result.country + ' ' : ''}`;
    setK(k);
  };

  return (
    <PlacesAutocomplete
      value={k}
      onChange={val => search(val)}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className=''>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text bg-white'><i className='fa fa-map-marker'></i></span>
            </div>
            <input
              {...getInputProps({
                placeholder: "country/city/zipcode",
                className: 'form-control border-left-0',
                autoComplete: 'off'
              })}
            />
          </div>

          {
            !!suggestions && suggestions.length > 0 &&
            <div className='position-absolute z-index-1 text-left mtz-card border my-2'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, idx) => {
                return (
                  <div className='mtz-cursor-pointer'
                    {...getSuggestionItemProps(suggestion, {
                    })}
                    key={suggestion.placeId}>
                    <span key={idx}>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          }
        </div>
      )}
    </PlacesAutocomplete>
  );
}

const AddrSearchGoogleWrapper = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"],
})(AddrSearch);

export default HomeSearchBoxNew;
