import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCompanyGroupPostCommentReplyLikes = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostCommentReplyLikeStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostCommentReplyLikeById = (companyGroupPostCommentReplyLikeId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes/${companyGroupPostCommentReplyLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCompanyGroupPostCommentReplyLike = (like) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, like, headers);
};

export const deleteCompanyGroupPostCommentReplyLikeById = (companyGroupPostCommentReplyLikeId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes/${companyGroupPostCommentReplyLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const deleteCompanyGroupPostCommentReplyLikes = (queries) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const updateCompanyGroupPostCommentReplyLikeById = (companyGroupPostCommentReplyLikeId, like) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-post-comment-reply-likes/${companyGroupPostCommentReplyLikeId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, like, headers);
};
