import React from 'react';
import AuthContext from '../../../../AuthContext';
import mtzApis from '../../../../services';

const { companyService, chatService } = mtzApis;

const CompanyGroupChatRoomCreateLink = ({ companyGroupId, profileName }) => {
    let [companyGroup, setCompanyGroup] = React.useState(null);
    let [myCompanyGroupMember, setMyCompanyGroupMember] = React.useState(null);
    let [groupName, setGroupName] = React.useState('');
    let [stats, setStats] = React.useState();

    const me = React.useContext(AuthContext);
    const closeRoomModalRef = React.useRef(null);


    React.useEffect(() => {
        let isMounted = true;

        (async () => {
            let companyGroup;
            if (profileName) {
                const companyGroups = await companyService.getCompanyGroups(
                    new URLSearchParams(`profileName=${profileName}`)
                );
                companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
            }

            if (companyGroup === null) {
                return;
            }
            let stats = await companyService.getCompanyGroupMemberStats(new URLSearchParams(`companyGroupIds=${companyGroup.id}`));
            if (stats)
                setStats(stats)

            if (isMounted) setCompanyGroup(companyGroup);
            let company = await companyService.getMyCompany();
            if (company) {
                let companyGroupMembers = await companyService.getCompanyGroupMembers(
                    new URLSearchParams(`companyGroupIds=${companyGroup.id}&userIds=${me.userId}`)
                );

                if (companyGroupMembers && companyGroupMembers.length > 0) {
                    if (isMounted) setMyCompanyGroupMember(companyGroupMembers[0]);
                }
            }
        })();

        return () => { isMounted = false; };
    }, [profileName, companyGroupId]);



    const handleCreateRoom = async () => {
        let companyGroupMembers;

        companyGroupMembers = await companyService.getCompanyGroupMembers(
            new URLSearchParams(`companyGroupIds=${companyGroup.id}`));


        let userIds = companyGroupMembers && companyGroupMembers.map((s) => s.userId);
        let meta = { companyGroupId: companyGroup.id }
        let metaData = [];
        metaData.push(meta);

        let groupRoom = { name: groupName, memberIds: userIds, meta: meta };
        mtzApis.startSpinningIcon();
        groupRoom = await chatService.createGroupRoom(groupRoom);
        mtzApis.stopSpinningIcon();
        if (groupRoom) {
            alert("Room created successfully")
            setGroupName(''); // Clear the group name
            // Close the modal
            if (closeRoomModalRef.current) {
                closeRoomModalRef.current.click();
            }


        }
    };



    return (
        <>
            {companyGroup && myCompanyGroupMember && myCompanyGroupMember.role === "ADMIN" &&
                <div>
                    <button className='btn btn-primary'
                        data-toggle='modal' data-target='#createRoomModal'>
                        Create Room
                    </button>
                </div>
            }

            <div className="modal fade" id="createRoomModal" tabIndex="-1" role="dialog" aria-labelledby="createRoomModalLabel" aria-hidden="true" style={{ zIndex: 1050 }}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createRoomModalLabel">Create Room</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={closeRoomModalRef}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="groupName">Group Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="groupName"
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                />
                            </div>
                        </div>
                        {stats && stats.all &&
                            <div className="modal-body">
                                <div className="form-group">
                                    <div className='text-danger'>Total Members in the room :</div>
                                    <div className='font-weight-bold'>{stats.all.count}</div>
                                </div>
                            </div>
                        }
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={handleCreateRoom}>Create</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanyGroupChatRoomCreateLink;
