import React from "react";
import mtzApis from "../../../../services";
import CompanyGroupBannerManager from "../../companyGroups2/CompanyGroupBannerManager";
import CompanyGroupLogoManager from "../CompanyGroupLogoManager";

const { companyService } = mtzApis;

function CompanyGroupLogoBanner({ companyGroupId, profileName }) {
    const bannerLogoRef = React.useRef();
    const avatar = React.useRef();
    let [bannerLogoWidth, setBannerLogoWidth] = React.useState();
    let [companyGroup, setCompanyGroup] = React.useState();

    const getBannerLogoWidth = () => {
        if (!bannerLogoRef || !bannerLogoRef.current) return;

        let w = bannerLogoRef.current.offsetWidth;
        setBannerLogoWidth(w);
        avatar.current.style.display = "inline-block";
        avatar.current.style.position = "relative";
        avatar.current.style.top = `-${w / 9}px`;
        avatar.current.style.left = `${w / 18}px`;
        bannerLogoRef.current.style.marginBottom = `-${w / 7}px`;
    };

    React.useEffect(() => {
        window.addEventListener("resize", getBannerLogoWidth);

        (async () => {
            if (profileName) {
                let companyGroups = await companyService.getCompanyGroups(
                    new URLSearchParams(`profileName=${profileName}`)
                );
                companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
            } else if (companyGroupId) {
                let companyGroups = await companyService.getCompanyGroups(
                    new URLSearchParams(`companyGroupIds=${companyGroupId}`)
                );
                companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
            }

            setCompanyGroup(companyGroup);
            getBannerLogoWidth();
        })();

        return () => {
            window.removeEventListener("resize", getBannerLogoWidth);
        };
    }, [companyGroupId, profileName]);

    return (
        <>
            {
                !companyGroup &&
                <div>
                    Loading...
                </div>
            }

            {
                companyGroup === null &&
                <div>
                    Invalid company group
                </div>
            }

            {
                companyGroup &&
                <div className="d-flex flex-column w-100 mtz-gap-16" style={{ mxHeight: "300px" }}>
                    <div ref={bannerLogoRef} className="w-100">
                        <div className="w-100 mb-4">
                            <CompanyGroupBannerManager
                                width={bannerLogoWidth}
                                profileName={companyGroup.profileName}
                                companyGroupId={companyGroup.companyId}
                            />
                        </div>
                        <div className="d-flex">
                            <div ref={avatar} className="rounded-circle shadow-sm bg-light">
                                <CompanyGroupLogoManager
                                    width={bannerLogoWidth / 8}
                                    profileName={companyGroup.profileName}
                                    companyGroupId={companyGroup.companyId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default CompanyGroupLogoBanner;
