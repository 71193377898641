import commonUtil from "../common";
import { METRIC_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const createEmailCampaignGroup = (params) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaign-groups`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, params, headers);
};

export const getEmailCampaignGroups = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaign-groups?${queries.toString()}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailCampaignGroupStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaign-groups/stats?${queries.toString()}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, null, headers);
};

export const deleteEmailCampaignGroupById = async id => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaign-groups/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return await commonUtil.httpclient(method, url, {}, headers);
};

export const updateEmailCampaignGroupById = async (id, update) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaign-groups/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return await commonUtil.httpclient(method, url, update, headers);
};

export const getEmailCampaignGroupById = async id => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaign-groups/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return await commonUtil.httpclient(method, url, {}, headers);
};
