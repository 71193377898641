import commonUtil from "../common";
import {  
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyNote = companyNote => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes`;
  let headers = {"Content-Type": "application/json",};
  return commonUtil.httpclient(method, url, companyNote, headers);
}

export const deleteCompanyNoteById = companyNoteId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/${companyNoteId}`;
  let headers = {"Content-Type": "application/json",};
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyNoteById = companyNoteId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/${companyNoteId}`;
  let headers = {"Content-Type": "application/json",};
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyNotes = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes?${queries}`;
  let headers = {"Content-Type": "application/json",};
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyNoteStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/stats?${queries}`;
  let headers = {"Content-Type": "application/json",};
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateCompanyNoteById = (companyNoteId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/${companyNoteId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}